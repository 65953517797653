const MessageLib = require('solclient-message');
const { BidiMap, Lazy } = require('solclient-eskit');
const { Bits, Convert } = require('solclient-convert');
const { ContentSummaryElement } = require('./content-summary-element');
const { ContentSummaryType } = require('./content-summary-types');
const { ErrorSubcode, OperationError } = require('solclient-error');
const { LOG_ERROR } = require('solclient-log');
const { SMFUH } = require('../message-objects');

const { lazyValue } = Lazy;
const {
  int8ToStr,
  int16ToStr,
  int24ToStr,
  int32ToStr,
} = Convert;

const delModeEnumBidiMap = lazyValue(
  () => {
    // Single bidirectional map for lookups. Note that the forward
    // keys are converted to strings.
    const source = [
      [0x00, MessageLib.MessageDeliveryModeType.NON_PERSISTENT],
      [0x01, MessageLib.MessageDeliveryModeType.PERSISTENT],
      [0x02, MessageLib.MessageDeliveryModeType.DIRECT],
    ].map(el => [el[0], el[1]]);
    return new BidiMap(...source);
  });
const lutDelModeToEnum = lazyValue(() => delModeEnumBidiMap.value.forward);
const lutEnumToDelMode = lazyValue(() => delModeEnumBidiMap.value.reverse);

/**
 * SMF TLV Param LUT
 * utTypeMap[uh][paramtype] is the binary prefix for a regular (not LW) TLV
 * parameter with uh and paramtype values as accessed in the array.
 *
 * @private
 */
const uhTypeMap = (() => {
  const result = [];
  const paramTypeBits = 5;
  const paramTypeCount = Math.pow(2, paramTypeBits);
  SMFUH.values.forEach((uh) => {
    result[uh] = [];
    for (let i = 0; i < paramTypeCount; ++i) {
      let byte1 = 0;
      byte1 = Bits.set(byte1, uh, 6, 2);
      byte1 = Bits.set(byte1, i, 0, paramTypeBits);
      result[uh][i] = int8ToStr(byte1);
    }
  });
  return result;
})();

/**
 * SMF TLV length map LUT
 *
 * lenMap[x] === String.fromCharCode(x)
 *
 * @private
 */
const lenMap = (new Array(256).fill(null).map((_, idx) => int8ToStr(idx)));

/**
 * SMF Lightweight Param LUT
 *
 * lightMap[uh][paramtype][len] is the prefix for an SMF LWP
 * with uh, paramtype and len values as accessed in the array.
 *
 * @private
 */
const lightMap = (() => {
  const result = [];
  const paramTypeBits = 3;
  const paramTypeCount = Math.pow(2, paramTypeBits);
  const lenBits = 2;
  const lenCount = Math.pow(2, lenBits);
  SMFUH.values.forEach((uh) => {
    result[uh] = [];
    for (let i = 0; i < paramTypeCount; ++i) {
      result[uh][i] = [];
      for (let j = 0; j < lenCount; ++j) {
        let byte1 = 0;
        byte1 = Bits.set(byte1, uh, 6, 2);
        byte1 = Bits.set(byte1, 1, 5, 1);
        byte1 = Bits.set(byte1, i, 2, 3);
        byte1 = Bits.set(byte1, j, 0, 2);
        result[uh][i][j] = int8ToStr(byte1);
      }
    }
  });
  return result;
})();

const ContentSummaryDecodeMap = [
  ContentSummaryType.XML_META,
  ContentSummaryType.XML_PAYLOAD,
  ContentSummaryType.BINARY_ATTACHMENT,
  ContentSummaryType.CID_LIST,
  ContentSummaryType.BINARY_METADATA,
];

const ParamParse = {};

ParamParse.FORCED_LENGTH_MODE = {
  FIVE:  5,
  SIX:   6
};

ParamParse.parseTopicQueueOffsets = function parseTopicQueueOffsets(dataBuf, offset) {
  const result = [];
  result[0] = dataBuf.readUInt8(offset);
  result[1] = dataBuf.readUInt8(offset + 1);
  return result;
};

ParamParse.parseResponseParam = function parseResponseParam(dataBuf, offset, paramLen) {
  const result = [];
  result[0] = dataBuf.readInt32BE(offset);
  if (paramLen > 4) {
    result[1] = dataBuf.toString('latin1', offset + 4, offset + paramLen);
  } else {
    result[1] = '';
  }
  return result;
};

ParamParse.parseDeliveryMode = function parseDeliveryMode(dataBuf, offset) {
  const delmode = dataBuf.readUInt8(offset);
  const lookup = lutDelModeToEnum.value.get(delmode);
  return lookup !== undefined ? lookup : MessageLib.MessageDeliveryModeType.DIRECT;
};

ParamParse.encDeliveryMode = function encDeliveryMode(delmode) {
  const lut = lutEnumToDelMode.value;
  const lookup = lut.get(delmode);
  return int8ToStr(lookup !== undefined ? lookup : MessageLib.MessageDeliveryModeType.DIRECT);
};

ParamParse.parseContentSummary = function parseContentSummary(dataBuf, offset, length) {
  const elements = [];
  let cumulativeSize = 0;
  let pos = offset;

  while (pos < offset + length) {
    const byte1 = dataBuf.readUInt8(pos);
    const elementType = Bits.get(byte1, 4, 4);
    const elementDeclaredLength = Bits.get(byte1, 0, 4);
    let elementSize = 0;
    switch (elementDeclaredLength) {
      case 2:
        elementSize = dataBuf.readUInt8(pos + 1);
        break;
      case 3:
        elementSize = dataBuf.readUInt16BE(pos + 1);
        break;
      case 4:
        elementSize = dataBuf.readUIntBE(pos + 1, 3);
        break;
      case 5:
        elementSize = dataBuf.readInt32BE(pos + 1);
        break;
      default:
        // Allow 1 and continue;
        break;
    }

    if (elementDeclaredLength === 0) {
      LOG_ERROR('Invalid content summary parameter - pos not advancing');
      return null;
    }
    pos += elementDeclaredLength;

    const cst = ContentSummaryDecodeMap[elementType];
    if (cst === undefined) {
      LOG_ERROR(`Unhandled element type ${elementType}`);
    }
    const currentElement = new ContentSummaryElement(cst, cumulativeSize, elementSize);
    elements.push(currentElement);
    cumulativeSize += elementSize;
  } // end while loop
  return elements;
};

ParamParse.encContentSummary = function encContentSummary(contentSummaryArr) {
  const messageElementDescriptions = [];
  for (let i = 0, n = contentSummaryArr.length; i < n; ++i) {
    // a ContentSummaryElement
    const currentContentSummary = contentSummaryArr[i];
    let currentSizeStr = '';
    let firstByte = Bits.set(0, currentContentSummary.type, 4, 4);
    if (currentContentSummary.length <= 255) {
      // element length: 2
      firstByte = Bits.set(firstByte, 2, 0, 4);
      currentSizeStr = int8ToStr(currentContentSummary.length);
    } else if (currentContentSummary.length <= 65535) {
      firstByte = Bits.set(firstByte, 3, 0, 4);
      currentSizeStr = int16ToStr(currentContentSummary.length);
    } else if (currentContentSummary.length <= 16777215) {
      firstByte = Bits.set(firstByte, 4, 0, 4);
      currentSizeStr = int24ToStr(currentContentSummary.length);
    } else {
      firstByte = Bits.set(firstByte, 5, 0, 4);
      currentSizeStr = int32ToStr(currentContentSummary.length);
    }
    messageElementDescriptions.push(int8ToStr(firstByte));
    messageElementDescriptions.push(currentSizeStr);
  }
  return messageElementDescriptions.join('');
};

ParamParse.encodeSMFParam = function encodeSMFParam(uh, paramtype, value) {
  if (value === undefined) {
    return uhTypeMap[uh][paramtype] + lenMap[2];
  }
  const len = value.length;
  if (len <= 253) {
    return uhTypeMap[uh][paramtype] + lenMap[len + 2] + value;
  }
  return uhTypeMap[uh][paramtype] + lenMap[0] + int32ToStr(len + 6) + value;
};


ParamParse.encodeSMFExtendedParam = function encodeSMFExtendedParam(uh, paramtype, value, forcedLengthMode = -1) {
  let byte1 = 0;
  byte1 = Bits.set(byte1, (uh ? 1 : 0), 7, 1);
  const length = ((value === undefined || value === null) ? 0 : value.length);
  // Bits 1-3 of an extended param (not named in the spec, lengthMode here)
  // can indicate value lengths 0-8 bytes, or 1-2 byte variable length.
  const lengthModeMap = { 0: 0, 1: 1, 2: 2, 4: 3, 8: 4 };
  let lengthMode = 0;
  let lengthString = '';

  // prevent unsupported value from breaking default behaviour
  if(forcedLengthMode !== ParamParse.FORCED_LENGTH_MODE.FIVE && forcedLengthMode !== ParamParse.FORCED_LENGTH_MODE.SIX) {
    forcedLengthMode = -1;
  }

  // EsLint made me do it.
  if (Object.prototype.hasOwnProperty.call(lengthModeMap, length)) {
    lengthMode = lengthModeMap[length];
  } else if (
    ((length < 253) && forcedLengthMode !== ParamParse.FORCED_LENGTH_MODE.SIX) 
    || (forcedLengthMode === ParamParse.FORCED_LENGTH_MODE.FIVE)
  ) {
    lengthMode = 5;
    lengthString = int8ToStr(length + 3);
  } else if (
    ((length < 256 * 256 - 4) && forcedLengthMode !== ParamParse.FORCED_LENGTH_MODE.FIVE) 
    || (forcedLengthMode === ParamParse.FORCED_LENGTH_MODE.SIX)
  ) {
    lengthMode = 6;
    lengthString = int16ToStr(length + 4);
  } else {
    LOG_ERROR(`Extended parameter type ${paramtype} is too long (${length} bytes) `);
    throw new OperationError(`Extended parameter (${paramtype}) over the 2^16 byte limit`,
        ErrorSubcode.PARAMETER_OUT_OF_RANGE);
  }

  byte1 = Bits.set(byte1, lengthMode, 4, 3);
  byte1 = Bits.set(byte1, (paramtype >> 8), 0, 4);
  const byte2 = paramtype & 0xFF;
  return int8ToStr(byte1) + int8ToStr(byte2) + lengthString + value;
};

ParamParse.encLightSMFParam = function encLightSMFParam(uh, paramtype, value) {
  return lightMap[uh][paramtype][value.length] + value;
};

module.exports.ParamParse = ParamParse;
