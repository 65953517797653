/**
 * <h1> Overview </h1>
 *
 * This is the Solace Corporation Messaging API for JavaScript. Concepts defined in this API are
 * similar to those defined in other Solace Messaging APIs for Java, C, and .NET.
 *
 * <h1> Concepts </h1>
 *
 * Some general concepts:
 *
 * <li> All function calls are non-blocking; confirmation, if requested, is returned to the calling
 * client application in the form of callbacks. </li>
 *
 */

/* _eslint-disable sort-requires/sort-requires */

// -------------------------- Solclient Factory ------------------------------
// Load me before the rest of the API as a plug-in point for modules
const FactoryLib = require('solclient-factory');
// ---------------------------------------------------------------------------

const {
  SolclientFactory,
  SolclientFactoryProfiles,
  SolclientFactoryProperties,
} = FactoryLib;

const {
  Long,
} = require('solclient-convert');
const {
  Destination,
  DestinationType,
  Topic,
} = require('solclient-destination');
const {
  ErrorSubcode,
  NotImplementedError,
  OperationError,
  RequestError,
  RequestEventCode,
} = require('solclient-error');
const {
  makeIterator,
} = require('solclient-eskit');
const {
  ConsoleLogImpl,
  LogImpl,
  LogLevel,
} = require('solclient-log');
const {
  Message,
  MessageCacheStatus,
  MessageDeliveryModeType,
  MessageDumpFlag,
  MessageOutcome,
  MessageType,
  MessageUserCosType,
  ReplicationGroupMessageId,
} = require('solclient-message');
const {
  MessageConsumer,
  MessageConsumerAcknowledgeMode,
  MessageConsumerEventName,
  MessageConsumerProperties,
  QueueBrowser,
  QueueBrowserEventName,
  QueueBrowserProperties,
} = require('solclient-message-consumer');
const {
  ReplayStartLocation,
  ReplayStartLocationBeginning,
} = require('solclient-replaystart');
const {
  MessagePublisherAcknowledgeMode,
  MessagePublisherProperties,
} = require('solclient-message-publisher');
const {
  Baggage,
  TraceContext,
  TraceContextSetter,
} = require('solclient-message-tracing');
const {
  AbstractQueueDescriptor,
  QueueAccessType,
  QueueDescriptor,
  QueueDiscardBehavior,
  QueuePermissions,
  QueueProperties,
  QueueType,
} = require('solclient-queue');
const {
  SDTField,
  SDTFieldType,
  SDTMapContainer,
  SDTStreamContainer,
  SDTUnsupportedValueError,
  SDTValueErrorSubcode,
} = require('solclient-sdt');
const {
  AuthenticationScheme,
  CapabilityType,
  MessageRxCBInfo,
  MutableSessionProperty,
  Session,
  SessionEvent,
  SessionEventCBInfo,
  SessionEventCode,
  SessionProperties,
  SessionState,
  SslDowngrade,
} = require('solclient-session');
const {
  CacheCBInfo,
  CacheLiveDataAction,
  CacheRequestResult,
  CacheReturnCode,
  CacheReturnSubcode,
  CacheSession,
  CacheSessionProperties,
} = require('solclient-solcache-session');
const {
  StatType,
} = require('solclient-stats');
const {
  TransportError,
  TransportProtocol,
} = require('solclient-transport');
const {
  Version,
} = require('solclient-util');

// --------------------------- Internal API -- do not use --------------------
// Load me last. I disappear in production mode
const _internal = require('./api-internal.js');
// ---------------------------------------------------------------------------


/**
 * @namespace
 * @public
 */
const solace = {
  AbstractQueueDescriptor,
  AuthenticationScheme,
  Baggage,
  CacheCBInfo,
  CacheLiveDataAction,
  CacheRequestResult,
  CacheReturnCode,
  CacheReturnSubcode,
  CacheSession,
  CacheSessionProperties,
  CapabilityType,
  ConsoleLogImpl,
  Destination,
  DestinationType,
  ErrorSubcode,
  LogImpl,
  LogLevel,
  Long,
  Message,
  MessageCacheStatus,
  MessageConsumer,
  MessageConsumerAcknowledgeMode,
  MessageConsumerEventName,
  MessageConsumerProperties,
  MessageDeliveryModeType,
  MessageDumpFlag,
  MessageOutcome,
  MessagePublisherAcknowledgeMode,
  MessagePublisherProperties,
  MessageRxCBInfo,
  MessageType,
  MessageUserCosType,
  MutableSessionProperty,
  NotImplementedError,
  OperationError,
  QueueAccessType,
  QueueBrowser,
  QueueBrowserEventName,
  QueueBrowserProperties,
  QueueDescriptor,
  QueueDiscardBehavior,
  QueuePermissions,
  QueueProperties,
  QueueType,
  ReplayStartLocation,
  /*
   * This should not be here ReplayStartLocationBeginning should not be a publicly exposed type.
   * This type must remain for backwards compatibility however it was never indented for use
   * by applications.
   * */
  ReplayStartLocationBeginning,
  ReplicationGroupMessageId,
  RequestError,
  RequestEventCode,
  SDTField,
  SDTFieldType,
  SDTMapContainer,
  SDTStreamContainer,
  SDTUnsupportedValueError,
  SDTValueErrorSubcode,
  Session,
  SessionEvent,
  SessionEventCBInfo,
  SessionEventCode,
  SessionProperties,
  SessionState,
  SolclientFactory,
  SolclientFactoryProfiles,
  SolclientFactoryProperties,
  SslDowngrade,
  StatType,
  Topic,
  TraceContext,
  TraceContextSetter,
  TransportError,
  TransportProtocol,
  Version,

  makeIterator,

  _internal, //
};

Object.assign(module.exports, solace);
