const { Parameter } = require('solclient-validate');
const { Convert } = require('solclient-convert');
const { TraceContextSetter } = require('./message-trace-context-setter');

const {
    isBoolean,
    isStringOrNothing,
} = Parameter;

const {
    stringToUint8Array,
    hexStringToUint8Array,
} = Convert;

/**
 * @classdesc
 * <b>This class abstracts readonly view on a metadata used for 
 * distributed message tracing with Solace messaging APIs
 * types. This class is for internal use only.
 * <p>
 * @hideconstructor
 * @memberof solace
 */
class TraceContext {

    /**
     * Abstract constructor for readonly view on metadata used 
     * for distributed message tracing.
     * 
     * @param {TraceContextSetter} traceContextSetter {TraceContextSetter}
     * 
     * @constructor
     * @hideconstructor
     * @private
     */
    constructor(traceContextSetter) {
        this._traceId = isStringOrNothing('traceId', traceContextSetter.traceId);
        this._spanId = isStringOrNothing('spanId', traceContextSetter.spanId);
        this._isSampled = isBoolean('isSampled', traceContextSetter.isSampled);
        this._traceState = traceContextSetter.traceState;
        this._version = traceContextSetter.version;
    }

    /**
     * Clone the a TraceContext object used 
     * for distributed message tracing.
     * 
     * @param {TraceContext} toClone {TraceContext}
     * @returns {TraceContext} the newly cloned TraceContext instance
     */
    static clone(toClone) {
        const newContextSetter = new TraceContextSetter();
        newContextSetter._setSpanId(toClone.getSpanId());
        newContextSetter._setTraceId(toClone.getTraceId());
        newContextSetter._setSampled(toClone.getIsSampled());
        newContextSetter._setTraceState(toClone.getTraceState());
        newContextSetter._setVersion(toClone.getVersion());
        return new TraceContext(newContextSetter);
    }

    /**
     * The version which for now is 1.
    */
    get version() {
        return this._version || 0x01; // version=0001 (4 bits, version=1);
    }
    /**
     * Gets the version associated with the message trace.
     * 
     * @returns {Number} The version encoded as Hex value
     */
    getVersion() {
        return this.version;
    }

    /**
     * The maximum allowed string size of trace state to propagate.
     * 
     * Refer: https://www.w3.org/TR/trace-context/#tracestate-limits
    */
    get MAX_TRACE_STATE_LENGTH() {
        return 512;
    }
 
    /**
     * The tranceId property as a 16-length string
    */
    get traceId() {
        return this._traceId;
    }
    /**
     * Gets the value of the trace identifier associated with the message.
     * 
     * @returns {String} value of trace identifier associated with the message as
     * 16-length string.
     */
    getTraceId() {
        return this._traceId;
    }
 
    /**
     * The spanId property a 8-length string
    */
    get spanId() {
        return this._spanId;
    }
    /**
     * Gets the value of the span identifier associated with the message.
     * 
     * @returns {String} value of span identifier associated with the message as
     * 8-length string.
     */
    getSpanId() {
        return this._spanId;
    }

    /**
     * The isSampled boolean property
    */
    get isSampled() {
        return this._isSampled;
    }
    /**
     * Returns true if the sampling for the associated message is on,
     * otherwise false.
     * 
     * @returns {Boolean} indicates whether the sampling is on or off
     */
    getIsSampled() {
        return this._isSampled || false;
    }

    /**
     * The traceState property
    */
    get traceState() {
        return this._traceState;
    }
    /**
     * Gets the value of the trace state associated with the message.
     * 
     * @returns {?String} The value of trace state associated with the message
     * @see {@link https://www.w3.org/TR/trace-context/#tracestate-header-field-values|w3c trace state format specification}
     */
    getTraceState() {
        return this._traceState || null;
    }
    /**
     * Gets the value of the trace state associated with the message.
     * 
     * @returns {?String} The value of trace state associated with the message
     * @see {@link https://www.w3.org/TR/trace-context/#tracestate-header-field-values|w3c trace state format specification}
     */
    getTruncatedTraceState() {
        return this._standardTraceStateTruncation(this.MAX_TRACE_STATE_LENGTH);
    }


    /**
     * It returns the encoded bytes that is 
     * passed to the SMF header to be encoded in 
     * SMF for the message.
     * 
     * @returns {?Uint8Array} The value of encoded trace span context
     */
    getEncodedTraceContext() {
        // format the string payload 
        // and return the correct format as a byte array or null
        if (this.traceId == null || this.spanId == null) {
            return null;
        }

        const traceStateLength = this.traceState == null ? 0 : this.traceState.length;

        // the fixed part of the encoded data is at least 32 bytes
        const contentBuffer = new ArrayBuffer(32 + traceStateLength);
        let offsetPos = 0; // start from the beginning of the buffer
        const contentBufferDataView = new DataView(contentBuffer);

        let byte1 = 0; // headerByte: version 4 bits, sampled 2 bits and RFU=0 2 bits
        // set the version to the four MSB
        byte1 |= (this.version << 4); // version=0001 (4 bits, version=1)
        byte1 |= this.isSampled ? 0x04 : 0x00; //sampled=0100 (2 bits, sampled=1 and 2 bits RFU=0)

        // write the 1 byte header
        contentBufferDataView.setUint8(offsetPos, byte1, false);
        offsetPos ++; // move pointer to next free position

        // write the first 16 bytes traceId
        const traceId16byte = hexStringToUint8Array(this.traceId); // get the 8 byte array
        for(let i = 0; i < 16; i ++) { // write the first 16 bytes
            // use BigEndian; litteEndian = false
            contentBufferDataView.setUint8(offsetPos + i, traceId16byte[i], false); // write the bytes
        }
        offsetPos += 16; // move pointer to next free position (plus 16 bytes)

        // write the first 8 bytes spanId
        const spanId8byte = hexStringToUint8Array(this.spanId) // get the 8 byte array
        for(let i = 0; i < 8; i ++) { // write the first 8 bytes
            // use BigEndian; litteEndian = false
            contentBufferDataView.setUint8(offsetPos + i, spanId8byte[i], false); // write the bytes
        }
        offsetPos += 8; // move pointer to next free position (plus 8 bytes)

        // write 1 byte InjectionStandard=1 (W3C)
        contentBufferDataView.setUint8(offsetPos, 0x01, false);
        offsetPos ++; // move pointer to next free position

        // write 4 bytes RFU=0
        // use BigEndian; litteEndian = false
        contentBufferDataView.setUint16(offsetPos, 0, false); // write first two RFU bytes of zeros
        contentBufferDataView.setUint16(offsetPos + 2, 0, false); // write last two RFU bytes of zeros
        offsetPos += 4; // move pointer to next free position

	    if (this.traceState == null) {
            // write 2 bytes TraceState length
            // use BigEndian; litteEndian = false
            contentBufferDataView.setUint16(offsetPos, 0, false); // write two bytes of zeros
            offsetPos += 2; // move pointer to next free position
	    } else {
	      // If required, apply truncation logic on traceState
	      const truncatedTraceState = this.getTruncatedTraceState();
	      if (truncatedTraceState != null) {
            // write 2 bytes TraceState length
            const traceStateLength = truncatedTraceState.length;
            // convert to two bytes
            const traceStateLengthBytes = new Uint16Array([traceStateLength]); // get 2 bytes of length
            // use BigEndian; litteEndian = false
            contentBufferDataView.setUint16(offsetPos, traceStateLengthBytes, false);
            offsetPos += 2; // move pointer to next free position

            // set the remaining trace state bytes
            const traceStateBytes = stringToUint8Array(truncatedTraceState);
            for(let i = 0; i < traceStateBytes.length; i ++) {
                // use BigEndian; litteEndian = false
                contentBufferDataView.setUint8(offsetPos + i, traceStateBytes[i], false); // write the bytes
            }
            offsetPos += traceStateBytes.length;
	      }
	    }

        // get the 8-byte array
	    return new Uint8Array(contentBuffer);
    }

    /**
     * Truncate long trace states properly
     * 
     * Refer: https://www.w3.org/TR/trace-context/#tracestate-limits
     * @private
     * @param {Number} maxTraceStateLength the maximum length for the trace state
     * @returns {?String} The truncated trace state
     */
    _standardTraceStateTruncation(maxTraceStateLength) {
        // cover corner cases
	    if (!this._traceState || this._traceState == null) {
	      return null;
	    } else if (this._traceState.length < maxTraceStateLength) {
	      return this._traceState; // no need to truncate
	    }
	
        const ignoredMembers = new Array();
	    const traceStateBuilderArray = new Array();
        let traceStateBuilderArrayLength = 0;
	    const listMembers = this._traceState.split(',');

	    for (let i = 0; i < listMembers.length; i++) {
            let listMember = listMembers[i]; // current iteration object
            if (listMember !== '' && listMember != null) {
                let currentMemberLength = listMember.length;
                if (currentMemberLength > 128) {
                    // first of all, let's ignore members with length > 128 char
                    ignoredMembers.push(listMember);
                } else {
                    let newExpectedLength = (traceStateBuilderArrayLength + currentMemberLength + (
                        traceStateBuilderArrayLength > 0 ? 1 : 0));

                    if (newExpectedLength <= maxTraceStateLength) {
                        // the comma seperator is added at the end when building the string
                        traceStateBuilderArray.push(listMember);
                        // increment by length of added member + 1 (for the comma)
                        traceStateBuilderArrayLength += (currentMemberLength + (
                            traceStateBuilderArrayLength > 0 ? 1 : 0));
                    } else {
                        ignoredMembers.push(listMember);
                    }
                }
            }
	    }
	
	    // See if we can add the ignored members now
	    for (let i = 0; i < ignoredMembers.length; i++) {
            let currentMember = ignoredMembers[i];
            let currentMemberLength = currentMember.length;

            let newExpectedLength = (traceStateBuilderArrayLength + currentMemberLength + (
                traceStateBuilderArrayLength > 0 ? 1 : 0));
            if (newExpectedLength <= maxTraceStateLength) {
                // the comma seperator is added at the end when building the string
                traceStateBuilderArray.push(currentMember);
                // increment by length of added member + 1 (for the comma)
                traceStateBuilderArrayLength += (currentMemberLength + (
                    traceStateBuilderArrayLength > 0 ? 1 : 0));
            }
	    }
	
        // build the string with comma seperating the members
	    return traceStateBuilderArray.join(',');
	}

    /**
     * Returns the string representation of this object
     * 
     * @override
     */
    toString() {
        return "{traceId=" + this.getTraceId()
            + ", spanId=" + this.getSpanId()
            + ", sampled=" + this.isSampled
            + ", traceState=" + ((this.traceState == null) ? "}" : "'" + this.traceState + "'}");
    }
}

module.exports.TraceContext = TraceContext;
