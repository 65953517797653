const { assert } = require('solclient-eskit');
const { hostListDNSFilter } = require('./host-list-dns-filter');
const { LogFormatter } = require('solclient-log');
const { parseURL } = require('solclient-util');

function parseURLs(rawURLs) {
  if (Array.isArray(rawURLs)) {
    return rawURLs.map(rawURL => parseURL(rawURL));
  }
  return parseURLs(rawURLs.split(/[,;]/));
}

/*
 *   "When using a host list, each time the API works through the host list without establishing
 *    a connection is considered an connect retry."
 *   SESSION_CONNECT_RETRIES: "When using a host list, this property defines how many times to
 *    try to connect or reconnect to a single host before moving to the next host in the list."
 *   Details: http://docs.solace.com/Solace-Messaging-APIs/Configuring-Connection-T.htm
*/

// In general:
// * set initial state to invalid so that we begin needing a transition.
// * preincrement all try counts on transition.
// Specifics:
// * Select try count for entire list based on whether we were connected.
// * Try count per host is the same for both connect and reconnect.
// * When the host changes, the waitTime is 0.
// * Overriding the above, when the host pointer wraps to 0, the waitTime is nonzero.

class HostInfo {
  constructor(props = { url: null, waitTime: 0 }) {
    Object.assign(this, props);
  }
}

/**
 * @private
 */
class HostList {
  constructor({
    url,
    connectRetries,
    reconnectRetries,
    connectRetriesPerHost,
    reconnectRetryWaitInMsecs,
  } = {}) {
    Object.assign(this, {
      hosts: parseURLs(url).map(e => e.href),

      // How many times will we traverse the host list without success?
      // Try to connect one more than the 'retryCount' because we always try once.
      connectTryCount: (connectRetries === -1
        ? Number.POSITIVE_INFINITY
        : connectRetries + 1
      ),

      // How many times will we traverse the host list after success?
      reconnectTryCount: (reconnectRetries === -1
        ? Number.POSITIVE_INFINITY
        : reconnectRetries
      ),

      // A value of –1 in the next assignements means retry forever - "null" will mean that
      // try to connect one more than the 'retryCount' because we always try once.
      connectTryCountPerHost: (connectRetriesPerHost === -1
        ? Number.POSITIVE_INFINITY
        : connectRetriesPerHost + 1
      ),

      // How long do we wait before reattempting the same host or wrapping around the list?
      reconnectRetryWaitInMsecs,

      // Mutating operations affect only this.
      _mutableState: {},

      logger: new LogFormatter('[host-list]'),
    });

    // call this to initialize the _mutableState property
    this.reset(); 

    assert(this.hosts.length >= 1);
    assert(this.connectTryCount >= 1);
    assert(this.reconnectTryCount >= 0);
    assert(this.connectTryCountPerHost >= 1);
  }

  /**
   * Call before first getNextHost() to validate that at least one URL contains a reachable
   * hostname.
   * @param {function(Error)} callback The callback to invoke when DNS resolution completes
   */
  resolveHosts(callback) {
    const { LOG_TRACE, LOG_WARN } = this.logger;
    hostListDNSFilter(this.hosts, (err, resolved) => {
      // Exit immediately if the filter threw.
      if (err) return callback(err);
      assert(resolved.length === this.hosts.length, 'Resolve did not return a result for all hosts');

      LOG_TRACE('Resolve result', resolved);
      let succeeded = 0;
      resolved.forEach((result) => {
        if (result.address) {
          ++succeeded;
        }
        if (!result.resolved) {
          // Only log if the lookup was actually performed
          return;
        }
        if (result.address) {
          LOG_TRACE('DNS resolve OK:    ', result.address, 'for', result.url);
        } else {
          LOG_WARN('DNS resolve FAILED:', result.error.code,
                   `${result.error.syscall}('${result.error.hostname}')`, 'for', result.url);
        }
      });
      // finished DNS resolution checks
      return callback(succeeded === 0 ? 'All hosts failed DNS resolution' : null);
    });
  }

  /**
   * @param {Object} state Properties for host selection logic
   * @memberof HostList
   */
  reset(state = { wasConnected: false, disconnected: false }) {
    // On reset, we always return to the beginning of the host list.
    // This facilitates DR recovery by returning to the primary router.
    // Set an invalid initial state that will trigger our first try.
    Object.assign(this._mutableState, {
      wasConnected: state.wasConnected,
      disconnected: state.disconnected,
      hostPointer:  0,
      hostTries:    0,
      listTries:    1,
      exhausted:    false,
      lastHostInfo: new HostInfo(),
    });
  }

  /**
   * !returns {HostInfo} Connection information for the next host.
   * @returns {String} The URL for the next host
   * @memberof HostList
   */
  getNextHost() {
    const { LOG_TRACE } = this.logger;

    const state = this._mutableState;
    const wasConnected = state.wasConnected;
    const lastHostInfo = state.lastHostInfo;

    assert(lastHostInfo, 'Next host request with no prior host info -- did you call reset()?');
    // Using a try/finally as a "goto end" to always log final state. Exceptions not expected
    // here, although if an assertion fails, the finally log should be helpful.
    try {
      // If this was passed into reset, the session is telling us to enforce no more hosts.
      if (state.disconnected) {
        LOG_TRACE('Host list set to disconnected, providing null next host');
        return null;
      }

      // If exhausted, this function has returned a null url already.
      assert(!state.exhausted, 'Next host request after host list exhausted');

      // Pull immutable properties from the instance
      const properties = Object.assign({
        hosts:        this.hosts,
        hostTriesMax: this.connectTryCountPerHost,
        listTriesMax: wasConnected ? this.reconnectTryCount : this.connectTryCount,
      });

      LOG_TRACE('Getting next host\n', 'properties', properties, '\nstate', state);
      LOG_TRACE('Last host', lastHostInfo);

      // Initial state was valid. This is a host try. Increment.
      ++state.hostTries;
      if (state.hostTries > properties.hostTriesMax) {
        // Increment host pointer, possibly putting it out of bounds.
        LOG_TRACE(`Exhausted ${state.hostTries} host tries for host ${lastHostInfo.url}.`);
        ++state.hostPointer;
        // If the host pointer is out of bounds, we are beginning a new list try.
        // It was either set out of bounds deliberately by reset() or it was
        // incremented out of bounds above.
        if (state.hostPointer >= properties.hosts.length) {
          // This is a new list try.
          ++state.listTries;
          if (state.listTries > properties.listTriesMax) {
            // Beginning this list try has exceeded our inclusive max. The host list is
            // exhausted.
            LOG_TRACE(`Exhausted host list at ${properties.listTriesMax} traversals.`);
            state.exhausted = true;
          } else {
            // Resetting the host pointer to begin this list try.
            LOG_TRACE(`Host list try (${state.listTries}/${properties.listTriesMax})`);
            state.hostPointer = 0;
            state.hostTries = 1; // this is the first try for this host
          }
        } else {
          state.hostTries = 1; // this is the first try for this host
        }
      } else {
        // Continue with this host.
        LOG_TRACE(`Host try (${state.hostTries}/${properties.hostTriesMax})`);
      }

      if (state.exhausted) {
        LOG_TRACE('All hosts exhausted');
        return null;
      }

      // Beyond this point, expect a valid host to be returned.

      const url = properties.hosts[state.hostPointer];
      assert(url, `No host at the host pointer! ${properties.hosts}[${state.hostPointer}]`);

      // Wait time conditions:
      // 1. On a new list (invalid lastHostInfo), zero waitTime.
      const isNewList = lastHostInfo.url === null;
      // 2a. On a new host, zero waitTime...
      const isNewHost = lastHostInfo.url !== url;
      // 2b. ...unless we are just restarting the list.
      const didJustFinishList = lastHostInfo.url !== url && state.hostPointer === 0;
      // (2b negates 2a for a new list)

      const waitTime = (isNewList || (isNewHost && !didJustFinishList))
        ? 0
        : this.reconnectRetryWaitInMsecs;

      // Session FSM was intended to use all of these, but handles its own events presently
      // and expects waitTime to be a property of the list that mutates per host, so that is
      // what is done.
      const hostInfo = new HostInfo({
        url,
        waitTime,
      });

      // Set last host for next time, and return
      LOG_TRACE('Returning host from', hostInfo);
      state.lastHostInfo = hostInfo;
      return hostInfo.url;
    } finally {
      LOG_TRACE('Final list state\n', state);
    }
  }

  get connectWaitTimeInMsecs() {
    assert(this._mutableState.lastHostInfo.url, 'Getting connectWaitTimeInMsecs having never called getNextHostInfo');
    return this._mutableState.lastHostInfo.waitTime;
  }

  currentHostToString() {
    const state = this._mutableState;
    const wasConnected = state.wasConnected;
    // Pull immutable properties from the instance
    const properties = Object.assign({
      hosts:        this.hosts,
      hostTriesMax: this.connectTryCountPerHost,
      listTriesMax: wasConnected ? this.reconnectTryCount : this.connectTryCount,
    });
    // host pointer is zero based index into the host list
    // so translate it to a human readable index
    const hostNumber = state.hostPointer + 1;
    return `host '${state.lastHostInfo.url}' (host ${hostNumber} of ${properties.hosts.length})(host connection attempt ${state.hostTries} of ${properties.hostTriesMax})(total ${wasConnected ? 'reconnection' : 'connection'} attempt ${state.listTries} of ${properties.listTriesMax})`;
  }
}

module.exports.HostList = HostList;
