/**
 * Created by rpaulson on 06/02/2017.
 */
const { BaseMessage } = require('./base-message');
const { Convert, Long } = require('solclient-convert');
const { DestinationType } = require('solclient-destination');
const { LOG_TRACE } = require('solclient-log');
const { OperationError } = require('solclient-error');
const { QueueAccessType,
        QueueDiscardBehavior } = require('solclient-queue');
const { QueuePermissions, QueueType } = require('solclient-queue');
const { ReplayStartType } = require('solclient-replaystart');
const { MessageOutcome } = require('solclient-message');
const { SMFAdProtocolMessageType } = require('../smf-adprotocol-message-types');
const { SMFAdProtocolParam } = require('../smf-adprotocol-params');
const { SMFHeader } = require('./smf-header');
const { SMFParameter } = require('./smf-parameter');
const { SMFProtocol } = require('../smf-protocols');
const { SMFUH } = require('./smf-uh');
const { StringUtils } = require('solclient-util');

// eslint-disable-next-line global-require
const BufferImpl = require('buffer').Buffer;

const {
  strToInt8,
  strToInt16,
  strToUInt32,
  strToUInt64,
} = Convert;

const bUInt8 = BufferImpl.prototype.readUInt8;
const bUInt16BE = BufferImpl.prototype.readUInt16BE;
const bUInt32BE = BufferImpl.prototype.readUInt32BE;
const bUInt64BE = function bUInt64BE(pos) { // invoked with "this" as the buffer.
  return Long.fromBits(this.readUInt32BE(pos + 4), this.readUInt32BE(pos), true);
};

const {
  nullTerminate,
  stripNullTerminate,
} = StringUtils;

// QUEUENAME/TOPICNAME
const EndpointTypeToParam = {
  [DestinationType.TOPIC]: SMFAdProtocolParam.DTENAME,
  [DestinationType.QUEUE]: SMFAdProtocolParam.QUEUENAME,
};

//Same as above, but for CREATE.
const QueueDescriptorTypeToParam = {
  [QueueType.TOPIC_ENDPOINT]: SMFAdProtocolParam.DTENAME,
  [QueueType.QUEUE]:          SMFAdProtocolParam.QUEUENAME,
};

const QUEUE_PERMISSIONS_TO_BITS = {
  [QueuePermissions.NONE]:         0x0000,
  [QueuePermissions.READ_ONLY]:    0x0001,
  [QueuePermissions.CONSUME]:      0x0003,
  [QueuePermissions.MODIFY_TOPIC]: 0x0007,
  [QueuePermissions.DELETE]:       0x000f,
};

const ACCESS_TYPE_INT_TO_ENUM = {
  0x01: QueueAccessType.EXCLUSIVE,
  0x02: QueueAccessType.NONEXCLUSIVE,
};

const ACCESS_TYPE_ENUM_TO_BITS = {
  [QueueAccessType.EXCLUSIVE]:    0x01,
  [QueueAccessType.NONEXCLUSIVE]: 0x02,
};

const DISCARD_ENUM_TO_VALUE = {
  [QueueDiscardBehavior.NOTIFY_SENDER_OFF]: 0x01,
  [QueueDiscardBehavior.NOTIFY_SENDER_ON]:  0x02,
};

function addQueueProperties(message, queueProperties, skipAccessType = false) {
  if (!queueProperties) {
    return;
  }
  const {
    accessType,
    discardBehavior,
    maxMessageRedelivery,
    maxMessageSize,
    permissions,
    quotaMB,
    respectsTTL,
  } = queueProperties;


  // [AssuredCtrl AllOthersPermissions Parameter]
  if (permissions && (QUEUE_PERMISSIONS_TO_BITS[permissions] !== undefined)) {
    message.addParameter(new SMFParameter(
      SMFUH.IGNORE,
      SMFAdProtocolParam.EP_ALLOTHER_PERMISSION,
      QUEUE_PERMISSIONS_TO_BITS[permissions]
    ));
  }

  //AccessType
  if (!skipAccessType &&
    accessType !== undefined &&
    ACCESS_TYPE_ENUM_TO_BITS[accessType] !== undefined
  ) {
    message.addParameter(new SMFParameter(
      SMFUH.IGNORE,
      SMFAdProtocolParam.ACCESSTYPE,
      ACCESS_TYPE_ENUM_TO_BITS[accessType]
    ));
  }

  // [AssuredCtrl EndpointQuotaMB Parameter]
  if (quotaMB !== null && quotaMB !== undefined) {
    message.addParameter(new SMFParameter(
      SMFUH.IGNORE,
      SMFAdProtocolParam.EP_QUOTA,
      quotaMB
    ));
  }

  // [AssuredCtrl EndpointMaxMessageSize Parameter]
  if (maxMessageSize !== undefined && maxMessageSize !== null) {
    message.addParameter(new SMFParameter(
      SMFUH.IGNORE,
      SMFAdProtocolParam.EP_MAX_MSGSIZE,
      maxMessageSize
    ));
  }

  let flags = 0x0000;
  if (discardBehavior !== null && discardBehavior !== undefined) {
    const discardBehaviorValue = DISCARD_ENUM_TO_VALUE[discardBehavior];
    flags |= (discardBehaviorValue << 12);
    // Omit cutThrough, << 14
  }
  if (flags /* is nonzero */) {
    message.addParameter(new SMFParameter(
      SMFUH.IGNORE,
      SMFAdProtocolParam.EP_BEHAVIOUR,
      flags
    ));
  }

  if (maxMessageRedelivery !== undefined && maxMessageRedelivery !== null) {
    message.addParameter(new SMFParameter(
      SMFUH.IGNORE,
      SMFAdProtocolParam.MAX_REDELIVERY,
      maxMessageRedelivery
    ));
  }

  if (respectsTTL !== undefined && respectsTTL !== null) {
    message.addParameter(new SMFParameter(
      SMFUH.IGNORE,
      SMFAdProtocolParam.EP_RESPECTS_TTL,
      (respectsTTL ? 0x1 : 0x0)
    ));
  }
}

/**
 * @internal
 */
class AdProtocolMessage extends BaseMessage {
  /**
   * @constructor
   * @param {SMFAdProtocolMessageType} [messageType=SMFAdProtocolMessageType.OPENPUBFLOW]
   *  The type of message
   * @param {Number} [version=3] The AD protocol version for the message
   * @extends {BaseMessage}
   * @memberof solace
   * @internal
   */
  constructor(messageType = 0, version = 3) {
    super(new SMFHeader(SMFProtocol.ADCTRL, 1));

    // Field: msgtype
    this.msgType = messageType;

    // Field: version
    this.version = version;
  }

  _readParameter(paramtype, decoder = null, bufMethod = null) {
    const param = this.getParameter(paramtype);
    if (param === undefined) return undefined;
    if (bufMethod && param.getBuffer()) {
      return bufMethod.call(param.getBuffer(), param.getBegin());
    }
    const value = param.getValue();
    return decoder ? decoder(value) : value;
  }

  /**
   * @returns {?QueueAccessType} The access type, if present
   */
  getAccessType() {
    const param = this._readParameter(SMFAdProtocolParam.ACCESSTYPE, strToInt8, bUInt8);
    return ACCESS_TYPE_INT_TO_ENUM[param];
  }

  /**
   * @returns {?Boolean} The active flow indication, if present
   */
  getActiveFlow() {
    return this._readParameter(SMFAdProtocolParam.ACTIVE_FLOW_INDICATION, strToInt8, bUInt8);
  }

  /**
   * @returns {?QueueDiscardBehavior} The discard behavior for the endpoint, if present
   */
  getQueueDiscardBehavior() {
    const param = this._readParameter(SMFAdProtocolParam.EP_BEHAVIOUR, strToInt16, bUInt16BE);
    if (param === undefined) {
      return undefined;
    }
    // Get NotifySender flag
    const masked = (param & 0x3000) >> 12;
    if (masked === DISCARD_ENUM_TO_VALUE[QueueDiscardBehavior.NOTIFY_SENDER_OFF]) {
      return QueueDiscardBehavior.NOTIFY_SENDER_OFF;
    } else if (masked === DISCARD_ENUM_TO_VALUE[QueueDiscardBehavior.NOTIFY_SENDER_ON]) {
      return QueueDiscardBehavior.NOTIFY_SENDER_ON;
    }
    return undefined;
  }
  /**
   * @returns {?Boolean} The Delivery Count setting of the endpoint, if present
   */
  getEndpointDeliveryCountSent() {
    const param = this._readParameter(SMFAdProtocolParam.EP_BEHAVIOUR, strToInt16, bUInt16BE);
    // Get NotifySender flag
    const masked = (param & 0x0c00) >> 10;
    switch (masked) {
      case 0 :
        return undefined;
      case 1 :
        return false;
      case 2:
        return true;
      default:
        //TODO: log: invalid flag value.
        return undefined;
    }
  }

  /**
   * @returns {?Number} The endpoint ID, if present
   */
  getEndpointId() {
    return this._readParameter(SMFAdProtocolParam.ENDPOINT_ID, strToUInt32, bUInt32BE);
  }

  /**
   * @returns {?Boolean} Whether endpoint respects TTL
   */
  getRespectsTTL() {
    const value = this._readParameter(SMFAdProtocolParam.EP_RESPECTS_TTL, strToInt8, bUInt8);
    if (value === undefined) {
      LOG_TRACE('respectsTTL missing from response.');
      return undefined;
    }
    LOG_TRACE(`respectsTTL present in response: ${value}`);
    return !!value;
  }

  /**
   * @returns {?String} The router-assigned flow name, if present
   */
  getFlowName() {
    return this._readParameter(SMFAdProtocolParam.FLOWNAME, stripNullTerminate);
  }

  /**
   * @returns {?Number} The router-assigned flow ID, if present
   */
  getFlowId() {
    return this._readParameter(SMFAdProtocolParam.FLOWID, strToUInt32, bUInt32BE);
  }

  /**
   * @returns {?Number} The quota on the endpoint, if present
   */
  getQuota() {
    return this._readParameter(SMFAdProtocolParam.EP_QUOTA, strToUInt32, bUInt32BE);
  }

  /**
   * @returns {?Number} The maximum message size of the endpoint, if present
   */
  getMaxMsgSize() {
    return this._readParameter(SMFAdProtocolParam.EP_MAX_MSGSIZE, strToUInt32, bUInt32BE);
  }

  /**
   * @returns {?String} The UTF-8 encoded, null terminated endpoint name
   */
  getTopicEndpointBytes() {
    return this._readParameter(SMFAdProtocolParam.DTENAME);
  }

  /**
   * @returns {?QueuePermissions} The granted permissions for the flow, if present
   */
  getGrantedPermissions() {
    const permissions =
      this._readParameter(SMFAdProtocolParam.GRANTED_PERMISSIONS, strToUInt32, bUInt32BE);
    let result;
    Object.keys(QUEUE_PERMISSIONS_TO_BITS).forEach((key) => {
      if (QUEUE_PERMISSIONS_TO_BITS[key] === permissions) {
        result = key;
      }
    });
    return result;
  }

  /**
   * @returns {?QueuePermissions} The permissions for other users for the endpoint, if present
   */
  getAllOthersPermissions() {
    const permissions =
      this._readParameter(SMFAdProtocolParam.EP_ALLOTHER_PERMISSION, strToUInt32, bUInt32BE);
    let result;
    Object.keys(QUEUE_PERMISSIONS_TO_BITS).forEach((key) => {
      if (QUEUE_PERMISSIONS_TO_BITS[key] === permissions) {
        result = key;
      }
    });
    return result;
  }

  /**
   * @returns {?Long} The last message ID acked, if present
   */
  getLastMsgIdAcked() {
    return this._readParameter(SMFAdProtocolParam.LASTMSGIDACKED,
                               strToUInt64, bUInt64BE);
  }

  /**
   * @returns {?Long} The last message ID received, if present
   */
  getLastMsgIdReceived() {
    return this._readParameter(SMFAdProtocolParam.LASTMSGIDRECEIVED,
                               strToUInt64, bUInt64BE);
  }

  /**
   * @returns {?Number} The publisher ID, if present
   */
  getPublisherId() {
    return this._readParameter(SMFAdProtocolParam.PUBLISHER_ID, strToUInt32, bUInt32BE);
  }

  /**
   * @returns {?Number} Whether we want flow change notifications, if present
   */
  getWantFlowChangeNotify() {
    return !!this._readParameter(SMFAdProtocolParam.WANT_FLOW_CHANGE_NOTIFY, strToInt8, bUInt8);
  }

  /**
   * @returns {?Number} The Window parameter, if present
   */
  getWindow() {
    return this._readParameter(SMFAdProtocolParam.WINDOW, strToInt8, bUInt8);
  }

  /**
   * @returns {?Number} The max redelivery parameter, if present
   */
  getMaxRedelivery() {
    return this._readParameter(SMFAdProtocolParam.MAX_REDELIVERY, strToInt8, bUInt8);
  }

  /**
   * @returns {?Number} The max unacked messages parameter, if present
   */
  getMaxUnackedMessages() {
    return this._readParameter(SMFAdProtocolParam.MAX_DELIVERED_UNACKED_MESSAGES_PER_FLOW,
                               strToUInt32, bUInt32BE);
  }

  /**
   * @returns {?Long} The endpointErrorId, if present
   */
  getEndpointErrorId() {
    return this._readParameter(SMFAdProtocolParam.ENDPOINT_ERROR_ID,
                               strToUInt64, bUInt64BE);
  }

  /**
   * @returns {?Long} The partitionGroupId, if present
   */
  getPartitionGroupId() {
    return this._readParameter(SMFAdProtocolParam.PARTITION_GROUP_ID,
                               strToInt16, bUInt16BE);
  }

  /**
   * @returns {?Long} The spoolerUniqueId, if present
   */
  getSpoolerUniqueId() {
    return this._readParameter(SMFAdProtocolParam.SPOOLER_UNIQUE_ID,
                               strToUInt64, bUInt64BE);
  }

  /**
   * Creates a CLOSEPUBFLOW message
   * @param {Number} flowId The publisher flow to close
   * @param {Number} correlationTag The correlation tag for the request
   * @returns {AdProtocolMessage} The newly created message
   * @internal
   * @static
   */
  static getCloseMessagePublisher(flowId,
                                  correlationTag) {
    const message = new AdProtocolMessage(SMFAdProtocolMessageType.CLOSEPUBFLOW);

    const header = message.smfHeader;
    header.pm_corrtag = correlationTag;

    message.addParameter(new SMFParameter(SMFUH.REJECT,
                                          SMFAdProtocolParam.FLOWID,
                                          flowId));
    return message;
  }


  /**
   * Creates a CREATE message
   * @param {solace.QueueDescriptor} queueDescriptor The endpoint descriptor for the create request
   * @param {?solace.QueueProperties} queueProperties The properties for the create request
   * @param {Number} correlationTag The correlation tag for the request
   * @returns {AdProtocolMessage} The newly created message
   * @internal
   * @static
   */
  static getCreate(
    queueDescriptor,
    queueProperties,
    correlationTag
  ) {
    const message = new AdProtocolMessage(SMFAdProtocolMessageType.CREATE);

    const header = message.smfHeader;
    header.pm_corrtag = correlationTag;

    /*
      {AssuredCtrl QueueName|TopicEndpointName Parameter}
      {AssuredCtrl Durability Parameter}
      [AssuredCtrl AllOthersPermission Parameter]
      [AssuredCtrl AccessType Parameter]
    [AssuredCtrl EndpointQuotaMB Parameter]
    [AssuredCtrl EndpointMaxMessageSize Parameter]
    [AssuredCtrl qEndpointBehaviourFlags Parameter]
    [AssuredCtrl MaxRedelivery Parameter]
    */

    // QueueName/TopicEndpointName : different param type for TE vs queue
    const endpointTypeParam = QueueDescriptorTypeToParam[queueDescriptor.type];
    if (endpointTypeParam === undefined) throw new OperationError('Unknown destination type');
    message.addParameter(new SMFParameter(
      SMFUH.REJECT,
      endpointTypeParam,
      nullTerminate(queueDescriptor.name)
    ));


    // [AssuredCtrl Durability Parameter]
    message.addParameter(new SMFParameter(
      SMFUH.IGNORE,
      SMFAdProtocolParam.EP_DURABLE,
      queueDescriptor.durable // better be...
    ));


    addQueueProperties(message, queueProperties);

    return message;
  }

  /**
   * Returns an AdProtocolMessage that describes a publisher open-flow request.
   * @static
   * @param {?Long} lastMsgIdAcked Last message ID acked, if re-opening
   * @param {?Long} lastMsgIdSent Last message ID sent, if re-opening
   * @param {Number} windowSize Desired window size
   * @param {String} flowName Last flow name in use, if re-opening
   * @param {Number} correlationTag Correlation tag for the request
   * @returns {solace.AdProtocolMessage} The OPENPUBFLOW message
   * @internal
   */
  static getOpenMessagePublisher(
            lastMsgIdAcked,
            lastMsgIdSent,
            windowSize,
            flowName,
            correlationTag) {
    const adMsg = new AdProtocolMessage(SMFAdProtocolMessageType.OPENPUBFLOW);

    const smfHeader = adMsg.smfHeader;
    smfHeader.pm_corrtag = correlationTag;

    if (lastMsgIdAcked !== undefined) {
      adMsg.addParameter(new SMFParameter(SMFUH.REJECT,
                                          SMFAdProtocolParam.LASTMSGIDACKED,
                                          lastMsgIdAcked));
    }
    if (lastMsgIdSent !== undefined) {
      adMsg.addParameter(new SMFParameter(SMFUH.REJECT,
                                          SMFAdProtocolParam.LASTMSGIDSENT,
                                          lastMsgIdSent));
    }

    adMsg.addParameter(new SMFParameter(SMFUH.REJECT,
                                        SMFAdProtocolParam.WINDOW,
                                        windowSize));

    adMsg.addParameter(new SMFParameter(SMFUH.IGNORE,
                                        SMFAdProtocolParam.FLOWNAME,
                                        flowName || ''));

    LOG_TRACE(`Create open publisher: lastMsgIdAcked=${lastMsgIdAcked} lastMsgIdSent=${lastMsgIdSent} window=${windowSize} flowName=${flowName || '(null)'}`);

    return adMsg;
  }

  /**
   * Returns an AdProtocolMessage that describes a subscriber (MessageConsumer) bind request.
   * @static
   * @param {solace.QueueDescriptor} queueDescriptor The endpoint descriptor for the bind request
   * @param {?solace.QueueProperties} queueProperties The properties for the bind request
   * @param {solace.Destination} endpoint The endpoint for the bind request
   * @param {?solace.Topic} topicSubscription The topic endpoint
   * @param {String} correlationTag The correlation tag for the request
   * @param {Number} windowSize The desired window size
   * @param {Boolean} [noLocal=false] If true, local publisher messages are not delivered
   * @param {Boolean} [wantFlowChangeUpdate] default is true if destination is a {solace.Queue}
   * @param {Long} [lastMsgIdAcked=Long.UZERO] Last message ID acked, if re-binding
   * @param {Long} [lastMsgIdReceived=Long.UZERO] Last message ID received, if re-binding
   * @param {Boolean} [browser=false] If true, flow is a queue browser
   * @param {ReplayStartLocation} [replayStartLocation=undefined] If set messages
   *         are first retrieved from the replay log before live messages are received.
   * @param {Long} [endpointErrorId=undefined] Endpoint Error ID identifying the flow
   *         when rebinding.
   *  @param {Long} [partitionGroupId=undefined] Partition Group ID for support of
   *          Partition Queue feature, associate new flow with the same
   *          PartitionGroupId as an old flow (e.g due to a reconnect).
   * @param {Boolean} [hasNackSupport=false] If true, flow is created with Consumer Redelivery
   * @returns {solace.AdProtocolMessage} The BIND message
   * @internal
   */
  static getOpenMessageConsumer(queueDescriptor,
                                queueProperties,
                                endpoint,
                                topicSubscription,
                                correlationTag,
                                windowSize,
                                noLocal,
                                wantFlowChangeUpdate,
                                lastMsgIdAcked = Long.UZERO,
                                lastMsgIdReceived = Long.UZERO,
                                browser = false,
                                replayStartLocation = undefined,
                                endpointErrorId = undefined,
                                partitionGroupId = undefined,
                                hasNackSupport = false) {
    /*
    QUEUE FLOW                                      TE FLOW

    {SMF Header, protocol=AssuredCtrl ttl=1}        {SMF Header, protocol=AssuredCtrl ttl=1}
    [Correlation Tag Parameter]                     [Correlation Tag Parameter]
    {AssuredCtrl Message Header, msgType=Bind}      {AssuredCtrl Message Header, msgType=Bind}

    {AssuredCtrl QueueName Parameter}               {AssuredCtrl TopicEndpointName Parameter}
    ***                                             {AssuredCtrl TopicName Parameter}
    [AssuredCtrl Last Message Id Acked Parameter]   ***
    [AssuredCtrl Last Message Id Recv'd Parameter]  ***
    {AssuredCtrl Transport Window Size Parameter}   {AssuredCtrl Transport Window Size Parameter}
    [AssuredCtrl Durability Parameter]              [AssuredCtrl Durability Parameter]
    [AssuredCtrl Message Selector Parameter]        [AssuredCtrl Message Selector Parameter]
    [AssuredCtrl FlowType Parameter]                [AssuredCtrl FlowType Parameter]
    [AssuredCtrl Selector Parameter]                [AssuredCtrl Selector Parameter]
    [AssuredCtrl AllOthersPermissions Parameter]    [AssuredCtrl AllOthersPermissions Parameter]
    [AssuredCtrl EndpointQuotaMB Parameter]         [AssuredCtrl EndpointQuotaMB Parameter]
    [AssuredCtrl EndpointMaxMessageSize Parameter]  [AssuredCtrl EndpointMaxMessageSize Parameter]
    [AssuredCtrl TransactedSessionId Parameter]     [AssuredCtrl TransactedSessionId Parameter]
    [AssuredCtrl NoLocal Parameter]                 [AssuredCtrl NoLocal Parameter]
    [AssuredCtrl wantFlowChangeUpdate Parameter]    ***
    [AssuredCtrl qEndpointBehaviourFlags Parameter] [AssuredCtrl qEndpointBehaviourFlags Parameter]
    [AssuredCtrl MaxRedelivery Parameter]           [AssuredCtrl MaxRedelivery Parameter]
    [AssuredCtrl browser Parameter]                 ***
    */
    const durable = queueDescriptor.durable;
    const endpointBytes = endpoint.bytes;
    const endpointType = endpoint.type;

    // {SMF Header, protocol=AssuredCtrl ttl=1}        {SMF Header, protocol=AssuredCtrl ttl=1}
    // [Correlation Tag Parameter]                     [Correlation Tag Parameter]
    // {AssuredCtrl Message Header, msgType=Bind}      {AssuredCtrl Message Header, msgType=Bind}
    const message = new AdProtocolMessage(SMFAdProtocolMessageType.BIND);
    const header = message.smfHeader;
    header.pm_corrtag = correlationTag;

    // {AssuredCtrl QueueName Parameter}               {AssuredCtrl TopicEndpointName Parameter}
    const endpointTypeParam = EndpointTypeToParam[endpointType];
    if (endpointTypeParam === undefined) throw new OperationError('Unknown destination type');
    message.addParameter(new SMFParameter(
      SMFUH.REJECT,
      endpointTypeParam,
      endpointBytes
    ));

    // ***                                             {AssuredCtrl TopicName Parameter}
    if (topicSubscription) {
      message.addParameter(new SMFParameter(
        SMFUH.REJECT,
        SMFAdProtocolParam.TOPICNAME,
        topicSubscription.bytes
      ));
    }

    if (endpointType === DestinationType.QUEUE) {
      //     [AssuredCtrl Last Message Id Acked Parameter]   ***
      message.addParameter(new SMFParameter(
        SMFUH.REJECT,
        SMFAdProtocolParam.LASTMSGIDACKED,
        lastMsgIdAcked
      ));
      //     [AssuredCtrl Last Message Id Recv'd Parameter]  ***
      message.addParameter(new SMFParameter(
        SMFUH.IGNORE,
        SMFAdProtocolParam.LASTMSGIDRECEIVED,
        lastMsgIdReceived
      ));
    }

    // {AssuredCtrl Transport Window Size Parameter}
    message.addParameter(new SMFParameter(
      SMFUH.REJECT,
      SMFAdProtocolParam.WINDOW,
      windowSize
    ));

    // [AssuredCtrl Durability Parameter]
    message.addParameter(new SMFParameter(
      SMFUH.IGNORE,
      SMFAdProtocolParam.EP_DURABLE,
      durable
    ));

    // Omit Message Selector
    // Omit FlowType, CONSUMER assumed (not BROWSER currently)
    // Omit TransactedSessionId

    addQueueProperties(message, queueProperties, true);

    if (noLocal /* is true */) {
      // [AssuredCtrl NoLocal Parameter]
      message.addParameter(new SMFParameter(
        SMFUH.REJECT,
        SMFAdProtocolParam.NOLOCAL,
        0x1
      ));
    }

    if (wantFlowChangeUpdate /* is true */) {
      // [AssuredCtrl wantFlowChangeUpdate Parameter]    ***
      message.addParameter(new SMFParameter(
        SMFUH.IGNORE,
        SMFAdProtocolParam.WANT_FLOW_CHANGE_NOTIFY,
        0x1
      ));
    }

    /**
     * A Flow that has Browser support cannot have NACK support 
     */
    if (hasNackSupport /* is true */) {
      // [AssuredCtrl FlowType Parameter]    ***
      message.addParameter(new SMFParameter(
        SMFUH.REJECT,
        SMFAdProtocolParam.FLOWTYPE,
        0x3 // create flow with Consumer Redelivery support
      ));
    } else if (browser /* is true */) {
      // [AssuredCtrl browser Parameter]    ***
      message.addParameter(new SMFParameter(
        SMFUH.REJECT,
        SMFAdProtocolParam.FLOWTYPE,
        0x2 // create flow with browser support
      ));
    }
    if (replayStartLocation !== undefined) {
      // [AssuredCtrl replay start location Parameter]
      let rsValue = replayStartLocation._replayStartValue;
      if (replayStartLocation._type === ReplayStartType.DATE) {
        const replayStartTimeMs = Long.fromNumber(replayStartLocation._replayStartValue, true);
        const replayStartTimeNs = replayStartTimeMs.multiply(1000000);
        rsValue = replayStartTimeNs;
      }
      message.addParameter(new SMFParameter(
        SMFUH.REJECT,
        SMFAdProtocolParam.REPLAY_START_LOCATION,
        {
          type:  replayStartLocation._type,
          value: rsValue,
        }
      ));
    }

    if (endpointErrorId !== undefined) {
      // [AssuredCtrl EndpointErrorId Parameter]    ***
      LOG_TRACE(`Adding endpointErrorId to message: ${endpointErrorId}`);
      message.addParameter(new SMFParameter(
        SMFUH.IGNORE,
        SMFAdProtocolParam.ENDPOINT_ERROR_ID,
        endpointErrorId
      ));
    }

    // support for PartitionGroupId. Only send when not null and undefined
    if (partitionGroupId !== undefined && partitionGroupId !== null) {
      // [AssuredCtrl PartitionGroupId Parameter]    ***
      LOG_TRACE(`Adding partitionGroupId to message: ${partitionGroupId}`);
      message.addParameter(new SMFParameter(
        SMFUH.IGNORE,
        SMFAdProtocolParam.PARTITION_GROUP_ID,
        partitionGroupId
      ));
    }

    return message;
  }

  /**
   * Creates an UNBIND request
   * @param {Number} flowId The flow ID to unbind
   * @param {Number} correlationTag The correlation tag for the request
   * @param {?Long} lastMessageIdAcked The last message ID marked as locally acked
   * @returns {AdProtocolMessage} The new UNBIND request
   * @static
   * @internal
   */
  static getCloseMessageConsumer(flowId,
                                 correlationTag) {
    const message = new AdProtocolMessage(SMFAdProtocolMessageType.UNBIND);

    const header = message.smfHeader;
    header.pm_corrtag = correlationTag;

    message.addParameter(new SMFParameter(SMFUH.REJECT,
                                          SMFAdProtocolParam.FLOWID,
                                          flowId));
    // linger: assume no

    return message;
  }

  static getDTEUnsubscribeMessage(correlationTag,
                                  topic) {
    const message = new AdProtocolMessage(SMFAdProtocolMessageType.UNSUBSCRIBE);
    const header = message.smfHeader;
    header.pm_corrtag = correlationTag;

    message.addParameter(new SMFParameter(
      SMFUH.REJECT,
      SMFAdProtocolParam.DTENAME,
      topic.getBytes()
    ));

    return message;
  }

  /**
   * @param {Number} flowId The flow on which to acknowledge messages.
   * @param {?Long} [lastMessageIdAcked=undefined] The transport acknowledges receipt of all
   *  messages up to and including this ID.
   * @param {?Long} [windowSize=undefined] The size to which the flow window should be set.
   * @param {?Map.<Array.<Array.<Long>>>} [applicationAckRanges=undefined] Low-high ID pairs of
   *  message IDs to acknowledge at the application level. To application ack a single message,
   *  pass `Map.set( MessageOutcome.ACCEPTED, [ [singleMessageId, singleMessageId] ] )`.
   * @returns {solace.AdProtocolMessage} A message containing the given parameters.
   * @internal
   * @static
   */
  static getAck(flowId,
                lastMessageIdAcked = undefined,
                windowSize = undefined,
                applicationAckRanges = undefined) {
    const message = new AdProtocolMessage(SMFAdProtocolMessageType.CLIENTACK);

    message.addParameter(new SMFParameter(
      SMFUH.REJECT,
      SMFAdProtocolParam.FLOWID,
      flowId
    ));

    if (lastMessageIdAcked) {
      message.addParameter(new SMFParameter(
        SMFUH.REJECT,
        SMFAdProtocolParam.LASTMSGIDACKED,
        lastMessageIdAcked
      ));
    }

    if (windowSize !== undefined && windowSize !== null) {
      // There are two windowSize options; we'll use the legacy one for smaller
      // window sizes
      message.addParameter(new SMFParameter(
        SMFUH.REJECT,
        (windowSize <= 0xFF) ? SMFAdProtocolParam.WINDOW : SMFAdProtocolParam.TRANSPORT_WINDOW,
        windowSize
      ));
    }

    if (applicationAckRanges && (applicationAckRanges.size > 0)) {
      // aggregate the total length of the ack/nack ranges
      let totalRangeLen  = 0;
      const allOutcomes = MessageOutcome.values;
      for(let i = 0; i < allOutcomes.length; i ++) {
        totalRangeLen += applicationAckRanges.has(allOutcomes[i]) ? applicationAckRanges.get(allOutcomes[i]).length : 0;
      }

      if (totalRangeLen > AdProtocolMessage.MAX_CLIENT_ACK_RANGES) {
        throw new OperationError('Application ack range count exceeds limit of 64');
      }
      message.addParameter(new SMFParameter(
        SMFUH.REJECT,
        SMFAdProtocolParam.APPLICATION_ACK,
        applicationAckRanges // DANGER: this needs to be encoded immediately, or else deep-cloned
      ));
    }

    return message;
  }

  /**
   * @param {Number} flowId The flow on which to acknowledge messages.
   * @param {?Long} [endpointErrorId=undefined] endpoint error id
   * @param {?Long} [lastMessageIdAcked=undefined] The transport acknowledges receipt of all
   *  messages up to and including this ID.
   * @returns {solace.AdProtocolMessage} A message containing the given parameters.
   * @internal
   * @static
   */
  static getUnbindAck(flowId, endpointErrorId = undefined, lastMessageIdAcked = undefined) {
    const message = new AdProtocolMessage(SMFAdProtocolMessageType.UNBIND);

    message.addParameter(new SMFParameter(
      SMFUH.REJECT,
      SMFAdProtocolParam.FLOWID,
      flowId
    ));

    if (endpointErrorId) {
      LOG_TRACE(`Adding endpointErrorId to unbind ack: ${endpointErrorId}`);
      message.addParameter(new SMFParameter(
        SMFUH.IGNORE,
        SMFAdProtocolParam.ENDPOINT_ERROR_ID,
        endpointErrorId
      ));
    }
    LOG_TRACE(`Not adding lastMessageIdAcked to unbind ack: ${lastMessageIdAcked}`);
    //if (lastMessageIdAcked) {
    //  LOG_TRACE(`Adding lastMessageIdAcked to unbind ack: ${lastMessageIdAcked}`);
    //  message.addParameter(new SMFParameter(
    //    SMFUH.REJECT,
    //    SMFAdProtocolParam.LASTMSGIDACKED,
    //    lastMessageIdAcked
    //  ));
    //}
    return message;
  }
}


AdProtocolMessage.MAX_CLIENT_ACK_RANGES = 64;

module.exports.AdProtocolMessage = AdProtocolMessage;
