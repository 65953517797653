const { Parameter: { isStringOrNothing } } = require('solclient-validate');

/**
 * @classdesc
 * <b>This class abstracts baggage metadata used for distributed
 * message tracing with Solace messaging APIs types. 
 * This class is for internal use only.
 * <p>
 * @hideconstructor
 * @memberof solace
 */
 class Baggage {
 
    /**
     * Get the value of the baggage associated with the message.
     * This method is for internal use only.
     *
     * @returns {?String} value of baggage associated with the message
     * @internal
     */
    getBaggage() {
        return this._baggage || null;
    }
 
    /**
     * Sets the value of the baggage associated with this message
     * This method is for internal use only.
     *
     * @param {?String} value The valid value of baggage associated with this  message
     * @see {@link https://www.w3.org/TR/2022/WD-baggage-20220928|w3c baggage format specification}  
     * @internal
     */
    setBaggage(value) {
        this._setBaggage(isStringOrNothing('baggage', value));
    }
    _setBaggage(value) {
        this._baggage = value;
    }
 
}

module.exports.Baggage = Baggage;
