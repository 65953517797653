const { Enum } = require('solclient-eskit');

/**
 * @private
 * @enum {number}
 */
const SMFAdProtocolParam = {
  LASTMSGIDSENT:                           0x01,
  LASTMSGIDACKED:                          0x02,
  WINDOW:                                  0x03,
  TRANSPORT_PRIORITY:                      0x04,
  APPLICATION_ACK:                         0x05,
  FLOWID:                                  0x06,
  QUEUENAME:                               0x07,
  DTENAME:                                 0x08,
  TOPICNAME:                               0x09,
  FLOWNAME:                                0x0a,
  EP_DURABLE:                              0x0b,
  ACCESSTYPE:                              0x0c,
  SELECTOR:                                0x0d,
  TRANSPORT_WINDOW:                        0x0e,
  LINGER_OPTION:                           0x0f,
  LASTMSGIDRECEIVED:                       0x10,
  EP_ALLOTHER_PERMISSION:                  0x11,
  FLOWTYPE:                                0x12,
  EP_QUOTA:                                0x13,
  EP_MAX_MSGSIZE:                          0x14,
  GRANTED_PERMISSIONS:                     0x15,
  EP_RESPECTS_TTL:                         0x16,
  TRANSACTION_CTRL_MESSAGE_TYPE:           0x17,
  TRANSACTED_SESSION_ID:                   0x18,
  TRANSACTED_SESSION_NAME:                 0x19,
  TRANSACTION_ID:                          0x1a,
  TRANSACTED_SESSION_STATE:                0x1b,
  TRANSACTION_FLOW_DESCRIPTOR_PUB_NOTIFY:  0x1c,
  TRANSACTION_FLOW_DESCRIPTOR_PUB_ACK:     0x1d,
  TRANSACTION_FLOW_DESCRIPTOR_SUB_ACK:     0x1e,
  NOLOCAL:                                 0x1f,
  ACTIVE_FLOW_INDICATION:                  0x20,
  WANT_FLOW_CHANGE_NOTIFY:                 0x21,
  EP_BEHAVIOUR:                            0x22,
  PUBLISHER_ID:                            0x23,
  APPLICATION_PUB_ACK:                     0x24,
  NUM_MESSAGES_SPOOLED:                    0x25,
  CUT_THROUGH:                             0x26,
  PUBLISHER_FLAGS:                         0x27,
  APP_MSG_ID_TYPE:                         0x28,
  QUEUE_ENDPOINT_HASH:                     0x29,
  MAX_REDELIVERY:                          0x2a,
  PAYLOAD:                                 0x2b,
  ENDPOINT_ID:                             0x2c,
  ACK_SEQUENCE_NUMBER:                     0x2d,
  ACK_RECONCILE_REQUEST:                   0x2e,
  START_OF_ACK_RECONCILE:                  0x2f,
  TIMESTAMP:                               0x30,
  MAX_DELIVERED_UNACKED_MESSAGES_PER_FLOW: 0x31,
  REPLAY_START_LOCATION:                   0x33,
  ENDPOINT_ERROR_ID:                       0x34,
  SPOOLER_UNIQUE_ID:                       0x36,
  PARTITION_GROUP_ID:                      0x38,
};

module.exports.SMFAdProtocolParam = Enum.new(SMFAdProtocolParam);
