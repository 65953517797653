const { AbstractQueueDescriptor } = require('./abstract-queue-descriptor');
const { Destination } = require('solclient-destination');

const DEFAULTS = {
  name:    undefined,
  durable: true,
};

/**
 * This class identifies a queue or topic endpoint on the message router.
 *
 * Operations that make use of queue descriptors include
 *  * {@link solace.Session#createMessageConsumer}
 *  * {@link solace.Session#dteUnsubscribe}
 *
 * @classdesc QueueDescriptor
 * @extends {AbstractQueueDescriptor}
 * @memberof solace
 */
class QueueDescriptor extends AbstractQueueDescriptor {

  /**
   * Creates an instance of {@link solace.QueueDescriptor}.
   *
   * @param {Object|solace.QueueDescriptor} queueSpec A specification for this descriptor.
   * @param {String} [queueSpec.name=null] The remote name to which this specification refers.
   * @param {solace.QueueType} queueSpec.type The type of queue for this specification.
   * @param {Boolean} [queueSpec.durable=true] `true` if this refers to a durable queue.
   */
  constructor(queueSpec) {
    super(queueSpec instanceof Destination
            ? { name: queueSpec.name, type: queueSpec.type } // synthesize durable later
            : Object.assign({ }, DEFAULTS, queueSpec));
  }

  /**
   * Gets/sets the remote name to which this descriptor refers.
   * @returns {String} The name of the queue.
   */
  getName() {
    return this._name;
  }
  get name() {
    return this.getName();
  }
  set name(value) {
    this._name = value;
  }

  [util_inspect_custom]() {
    return {
      'name':    this.name,
      'type':    this.type,
      'durable': this.durable,
    };
  }

  /**
   * An informational summary of this object, subject to change.
   * @returns {String} A summary of this object.
   */
  toString() {
    return `${super.toString()} '${this.getName() || ''}'`;
  }

  /**
   * @static
   * @param {any} spec The descriptor spec
   * @returns {QueueDescriptor|AbstractQueueDescriptor} A new descriptor for the spec
   * @private
   */
  static createFromSpec(spec) {
    if (spec.name) return new QueueDescriptor(spec);
    return AbstractQueueDescriptor(spec);
  }
}

module.exports.QueueDescriptor = QueueDescriptor;
