const { Parameter } = require('solclient-validate');
const { Convert } = require('solclient-convert');

// eslint-disable-next-line global-require
const BufferImpl = require('buffer').Buffer;

const {
    isNumber,
    isBoolean,
    isString,
    isStringOrNothing,
} = Parameter;

const { uint8ArrayToString } = Convert;

/**
 * @classdesc
 * <b>This class abstracts settable metadata used for 
 * distributed message tracing with Solace messaging APIs 
 * types. This class is for internal use only.
 * <p>
 * @hideconstructor
 * @memberof solace
 */
 class TraceContextSetter {

    /**
     * Abstract constructor for metadata used 
     * for distributed message tracing.
     * 
     * @constructor
     * @hideconstructor
     * @private
     */
    constructor() {
        this._traceId = null;
        this._spanId = null;
        this._isSampled = false;
        this._traceState = null;
        this._version = 0x01; // version=0001 (4 bits, version=1);
    }

    /**
     * Clone this TraceContextSetter object.
     * 
     * @returns {TraceContextSetter} the cloned TraceContextSetter instance
     */
    clone() {
        const _clonedContextSetter = new TraceContextSetter();
        _clonedContextSetter._setSpanId(this._spanId);
        _clonedContextSetter._setTraceId(this._traceId);
        _clonedContextSetter._setSampled(this._isSampled);
        _clonedContextSetter._setTraceState(this._traceState);
        _clonedContextSetter._setVersion(this._version);
        return _clonedContextSetter;
    }

    /**
     * The length of the traceId bytes in the binary message
    */
    static get TRACE_ID_BYTES_LENGTH() {
        return 16;
    }

    /**
     * The length of the spanId bytes in the binary message
    */
    static get SPAN_ID_BYTES_LENGTH() {
        return 8;
    }

    /**
     * The version which for now is 1. -> 0001
    */
    get version() {
        return this._version || 0x01; // version=0001 (4 bits, version=1);;
    }
    /**
     * Sets the version
     * 
     * @param {Number} value The version encoded as Hex value
     */
    setVersion(value) {
        this._setVersion(isNumber('version', value));
    }
    _setVersion(value) {
        this._version = value;
    }

    /**
     * @private
    */
    get traceId() {
        return this._traceId;
    }
    /**
     * Sets the value of the trace identifier associated with the message.
     * 
     * @param {String} value The trace identifier encoded as a 16-length Hex string
     */
    setTraceId(value) {
        this._setTraceId(isString('traceId', value));
    }
    _setTraceId(value) {
        this._traceId = value;
    }
 
    /**
     * @private
    */
    get spanId() {
        return this._spanId;
    }
    /**
     * Sets the value of the span identifier associated with the message.
     * 
     * @param {String} value The trace identifier encoded as a 8-length Hex string
     */
    setSpanId(value) {
        this._setSpanId(isString('spanId', value));
    }
    _setSpanId(value) {
        this._spanId = value;
    }
 
    /**
     * @private
    */
    get isSampled() {
        return this._isSampled || false;
    }
    /**
     * Turns on or off sampling for the associated message.
     * 
     * @param {Boolean} value if true sampling is on, off otherwise
     */
    setSampled(value) {
        this._setSampled(isBoolean('isSampled', value));
    }
    _setSampled(value) {
        this._isSampled = value;
    }
 
    /**
     * @private
    */
    get traceState() {
        return this._traceState;
    }
    /**
     * Sets the value of the trace state associated with the message.
     * 
     * @param {?String} value The value of trace state associated with the message
     * @see {@link https://www.w3.org/TR/trace-context/#tracestate-header-field-values|w3c trace state format specification}
     */
    setTraceState(value) {
        this._setTraceState(isStringOrNothing('traceState', value));
    }
    _setTraceState(value) {
        this._traceState = value;
    }

    /**
     * Gets a new instance of the Message Trace Context Setter 
     * from the values in the SMF header associated with the message.
     * 
     * @param {Buffer | Uint8Array | String | null} traceContextValue The value of trace context associated with the message
     * @returns {solace.TraceContextSetter | null} Context setter object
     */
    static fromTraceContext(traceContextValue) {
        // implementation here
        if (traceContextValue == null) {
            return null;
        }

        let traceContextBuffer = null;
        if (BufferImpl.isBuffer(traceContextValue)) {
            traceContextBuffer = traceContextValue; // do nothing since it is already a buffer
        } else if (typeof traceContextValue === 'string') {
            traceContextBuffer = BufferImpl.from(traceContextValue, 'latin1');
        }

        // the trace context value is at least 32 bytes (without trace state)
        if (!traceContextBuffer || traceContextBuffer.length < 32) {
            return null;
        }

        try {
            const traceContextBytes = (new Uint8Array(traceContextBuffer)).buffer;
            let bytesRead = 0; // to track the byte read offsets

            const traceContextSetter = new TraceContextSetter();

            const firstByte = traceContextBytes.slice(bytesRead, bytesRead + (1));
            const byte1DataView = new DataView(firstByte, 0, 1);
            let byte1 = byte1DataView.getUint8(bytesRead);// get the first byte

            const version = byte1 >> 4; // get the version from the four MSB
            traceContextSetter.setVersion(version); // set the version

            const isSampled = ((byte1 & 0x0F) == 0x04);
            traceContextSetter.setSampled(isSampled); // set the sampled status
            bytesRead++;

            const traceId16Bytes = traceContextBytes.slice(bytesRead, bytesRead + (16));
            const traceId = uint8ArrayToString(traceId16Bytes, 'hex'); // set the traceId
            traceContextSetter.setTraceId(traceId);
            bytesRead += TraceContextSetter.TRACE_ID_BYTES_LENGTH;

            const spanId8Bytes = traceContextBytes.slice(bytesRead, bytesRead + (8));
            const spanId = uint8ArrayToString(spanId8Bytes, 'hex'); // set the spanId
            traceContextSetter.setSpanId(spanId);
            bytesRead += TraceContextSetter.SPAN_ID_BYTES_LENGTH;

            bytesRead ++; // Skip InjectionStandard byte
            bytesRead += 4; // Skip the 4 RFU bytes

            const traceStateLengthBytes = traceContextBytes.slice(bytesRead, bytesRead + (2));
            const traceStateLengthDataView = new DataView(traceStateLengthBytes, 0, traceStateLengthBytes.byteLength);
            const traceStateLength = traceStateLengthDataView.getUint16(0, false);// get the trace length
            bytesRead += 2; // move pointer past length bytes

            // if there is a trace state value, read it
            if (traceStateLength > 0) {
                const traceStateBytes = traceContextBytes.slice(bytesRead, bytesRead + (traceStateLength));
                const traceState = uint8ArrayToString(traceStateBytes); // set the trace state
                traceContextSetter.setTraceState(traceState);
            }
            return traceContextSetter;
        } catch (ex) {
            return null;
        }
    }
}

module.exports.TraceContextSetter = TraceContextSetter;
