const { Enum } = require('solclient-eskit');

/**
 * Defines an error subcode enumeration which is returned as a property of
 * the errors/exceptions thrown by the API. The subcode provides more detailed
 * error information.
 *
 * The following subcodes can apply to error responses resulting from
 * any API method.
 * * {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}
 * * {@link solace.ErrorSubcode.PARAMETER_CONFLICT}
 * * {@link solace.ErrorSubcode.INTERNAL_ERROR}
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const ErrorSubcode = {
  /////////////
  // SESSION //
  /////////////
  /**
   * Errors that do not have a proper subcode.
   */
  UNKNOWN_ERROR:                                 0xFFFFFFFF,
  /**
   * No error is associated with this event.
   */
  NO_ERROR:                                      0,
  /**
   * The session is already connected.
   */
  // SESSION_ALREADY_CONNECTED: 1,
  /**
   * The session is not connected.
   */
  SESSION_NOT_CONNECTED:                         2,
  /**
   * The performed session operation is invalid given the state
   * or configuration of the session.
   * @deprecated Use {@link solace.ErrorSubCode.INVALID_OPERATION}
   */
  INVALID_SESSION_OPERATION:                     3,
  /**
   * The performed session operation is invalid given the state
   * or configuration of the session.
   */
  INVALID_OPERATION:                             3,
  /**
   * An API call failed due to a timeout.
   */
  TIMEOUT:                                       4,
  /////////////////
  // MESSAGE VPN //
  /////////////////
  /**
   * The Message VPN name configured for the session does not exist.
   *
   * Causes:
   *  * 403 Message VPN Not Allowed
   */
  MESSAGE_VPN_NOT_ALLOWED:                       5,
  /**
   * The Message VPN name set for the session (or the default VPN if none
   * was set) is currently shutdown on the router.
   *
   * Causes:
   *  * 503 Message VPN Unavailable
   */
  MESSAGE_VPN_UNAVAILABLE:                       6,
  ////////////
  // CLIENT //
  ////////////
  /**
   * The username for the client is administratively shutdown
   * on the router.
   *
   * Causes:
   *  * 403 Client Username Is Shutdown
   */
  CLIENT_USERNAME_IS_SHUTDOWN:                   7,
  /**
   * The username for the session has not been set and dynamic
   * clients are not allowed.
   *
   * Causes:
   *  * 403 Dynamic Clients Not Allowed
   */
  DYNAMIC_CLIENTS_NOT_ALLOWED:                   8,
  /**
   * The session is attempting to use a client name that is
   * in use by another client, and the router is configured to reject the
   * new session.
   * A client name cannot be used by multiple clients in the same Message
   * VPN.
   *
   * Causes:
   *  * 403 Client Name Already In Use
   */
  CLIENT_NAME_ALREADY_IN_USE:                    9,
  /**
   * The client name chosen has been rejected as invalid by the router.
   *
   * Causes:
   *  * 400 Client Name Parse Error
   */
  CLIENT_NAME_INVALID:                           10,
  /**
   * The client login is not currently possible because a previous
   * instance of same client was being deleted.
   *
   * Causes:
   *  * 503 Subscriber Delete In Progress
   */
  CLIENT_DELETE_IN_PROGRESS:                     11,
  /**
   * The client login is not currently possible because the maximum
   * number of active clients on router has already been reached.
   *
   * Causes:
   *  * 503 Too Many Clients
   *  * 503 Too Many Connections for VPN
   */
  TOO_MANY_CLIENTS:                              12,
  /**
   * The client could not log into the router.
   *
   * Causes:
   *  * 401 error codes
   *  * 404 error codes
   *  * Failed to send a session setup message in the transport.
   */
  LOGIN_FAILURE:                                 13,
  //////////
  // VRID //
  //////////
  /**
   * An attempt was made to connect to the wrong IP address on
   * the router (must use CVRID if configured), or the router CVRID has
   * changed and this was detected on reconnect.
   *
   * Causes:
   *  * 403 Invalid Virtual Router Address
   */
  INVALID_VIRTUAL_ADDRESS:                       14,
  /////////
  // ACL //
  /////////
  /**
   * The client login to the router was denied because the
   * IP address/netmask combination used for the client is designated in the
   * ACL (Access Control List) profile associated with that client.
   *
   * Causes:
   *  * 403 Forbidden
   */
  CLIENT_ACL_DENIED:                             15,
  /**
   * Adding a subscription was denied because it matched a
   * subscription that was defined as denied on the ACL (Access Control List)
   * profile associated with the client.
   *
   * Causes:
   *  * 403 Subscription ACL Denied
   */
  SUBSCRIPTION_ACL_DENIED:                       16,
  /**
   * A message could not be published because its topic matched
   * a topic defined as denied on the ACL (Access Control List) profile
   * associated with the client.
   *
   * Causes:
   *  * 403 Publish ACL Denied
   */
  PUBLISH_ACL_DENIED:                            17,
  ////////////////
  // VALIDATION //
  ////////////////
  /**
   * An API call was made with an out-of-range parameter.
   */
  PARAMETER_OUT_OF_RANGE:                        18,
  /**
   * An API call was made with a parameter combination
   * that is not valid.
   */
  PARAMETER_CONFLICT:                            19,
  /**
   * An API call was made with a parameter of incorrect type.
   */
  PARAMETER_INVALID_TYPE:                        20,
  //////////////////
  // FATAL ERRORS //
  //////////////////
  /**
   *  An API call had an internal error (not an application fault).
   */
  INTERNAL_ERROR:                                21,
  /**
   * An API call failed due to insufficient space in the transport
   * buffer to accept more data,
   * or due to exhaustion of the Guaranteed Messaging Window on a publisher.
   * After an insufficient space error of either kind, the
   * listeners on the {@link solace.SessionEventCode#event:CAN_ACCEPT_DATA}
   * event are notified when it is possible to retry the failed operation.
   */
  INSUFFICIENT_SPACE:                            22,
  /**
   * The message router has rejected the request. All available
   * resources of the requested type are in use.
   *
   * Causes:
   *  * 400 Not Enough Space
   */
  OUT_OF_RESOURCES:                              23,
  /**
   * An API call failed due to a protocol error with the router
   * (not an application fault).
   */
  PROTOCOL_ERROR:                                24,
  /**
   * An API call failed due to a communication error. This typically indicates the
   * transport connection to the message router has been unexpectedly closed.
   */
  COMMUNICATION_ERROR:                           25,
  ////////////////
  // KEEP ALIVE //
  ////////////////
  /**
   * The session keep-alive detected a failed session.
   */
  KEEP_ALIVE_FAILURE:                            26,
  /////////////////////
  // MESSAGE RELATED //
  /////////////////////
  /**
   * An attempt was made to use a topic which is longer
   * than the maximum that is supported.
   */
  // TOPIC_TOO_LARGE: 27,
  /**
   * A send call was made that did not have a topic in a mode
   * where one is required (for example, client mode).
   */
  TOPIC_MISSING:                                 28,
  /**
   *  An attempt was made to send a message with a total
   * size greater than that supported by the protocol. (???)
   */
  //  MAX_TOTAL_MSGSIZE_EXCEEDED: 29,
  /**
   * An attempt was made to send a message with user data larger
   * than the maximum that is supported.
   */
  // USER_DATA_TOO_LARGE: 30,
  /**
   * An attempt was made to use a topic which has a syntax that
   * is not supported.
   *
   * Causes:
   *  * 400 Topic Parse Error
   */
  INVALID_TOPIC_SYNTAX:                          31,
  /**
   * The client attempted to send a message larger than that
   * supported by the router.
   *
   * Causes:
   *  * 400 Document Is Too Large
   *  * 400 Message Too Long
   */
  MESSAGE_TOO_LARGE:                             32,
  /**
   * The router could not parse an XML message.
   *
   * Causes:
   *  * 400 XML Parse Error
   */
  XML_PARSE_ERROR:                               33,
  ///////////////////
  // SUBSCRIPTIONS //
  ///////////////////
  /**
   * The client attempted to add a subscription that already
   * exists. This subcode is only returned if the session property
   * 'IgnoreDuplicateSubscriptionError' is not enabled.
   *
   * Causes:
   * 400 Subscription Already Exists)
   */
  SUBSCRIPTION_ALREADY_PRESENT:                  34,
  /**
   * The client attempted to remove a subscription which did not exist.
   * This subcode is only returned if the session property
   * 'IgnoreDuplicateSubscriptionError' is not enabled.
   *
   * Causes:
   * 400 Subscription Not Found)
   */
  SUBSCRIPTION_NOT_FOUND:                        35,
  /**
   * The client attempted to add/remove a subscription that
   * is not valid.
   *
   * Causes:
   *  * 400 Subscription Parse Error
   */
  SUBSCRIPTION_INVALID:                          36,
  /**
   * The router rejected a subscription add or remove request
   * for a reason not separately enumerated.
   */
  SUBSCRIPTION_ERROR_OTHER:                      37,
  /**
   * The client attempted to add a subscription that
   * exceeded the maximum number allowed.
   *
   * Causes:
   *  * 400 Max Num Subscriptions Exceeded
   */
  SUBSCRIPTION_TOO_MANY:                         38,
  /**
   *  The client attempted to add a subscription which already
   * exists but it has different properties.
   *
   * Causes:
   *  * 400 Subscription Attributes Conflict With Existing Subscription
   */
  SUBSCRIPTION_ATTRIBUTES_CONFLICT:              39,
  /**
   * The client attempted to establish a session with No Local
   * enabled and the capability is not supported by the router.
   */
  NO_LOCAL_NOT_SUPPORTED:                        40,
  ////////////////////
  // UNKNOWN ERRORS //
  ////////////////////
  /**
   * The router rejected a control message for another reason
   * not separately enumerated.
   */
  // CONTROL_ERROR_OTHER: 41,
  /**
   * The router rejected a data message for a reason
   * not separately enumerated.
   */
  DATA_ERROR_OTHER:                              42,
  //////////////////////
  // TRANSPORT ERRORS //
  //////////////////////
  /**
   * Failed to create the HTTP connection.
   */
  CREATE_XHR_FAILED:                             43,
  /**
   * Failed to create the transport.
   */
  CONNECTION_ERROR:                              44,
  /**
   * Failed to decode the data.
   */
  DATA_DECODE_ERROR:                             45,
  /**
   * @deprecated
   * The session was inactive for too long.
   *
   * Causes:
   * * 400 Inactivity Timeout
   */
  INACTIVITY_TIMEOUT:                            46,
  /**
   * @deprecated
   * The router does not know this session's identifier.
   *
   * Causes:
   * * 400 Unknown Transport Session Identfier
   */
  UNKNOWN_TRANSPORT_SESSION_ID:                  47,
  /**
   * @deprecated This subcode is not used since 10.0.0.
   * Guaranteed Messages (with a delivery mode other than DIRECT) are not supported.
   */
  AD_MESSAGING_NOT_SUPPORTED:                    48,
  /**
   * @deprecated
   * Creating the WebSocket transport failed.
   */
  CREATE_WEBSOCKET_FAILED:                       49,
  /**
   * An attempt to perform an operation using a VPN that is configured to be
   * STANDBY for replication.
   *
   * Causes:
   * * 403 Replication Is Standby
   */
  REPLICATION_IS_STANDBY:                        50,
  ////////////////////
  // Authentication //
  ////////////////////
  /**
   * Basic authentication is administratively shut down on the
   * router.
   *
   * Causes:
   *  * 403 Basic Authentication is Shutdown
   */
  BASIC_AUTHENTICATION_IS_SHUTDOWN:              51,
  /**
   * Client certificate authentication is administratively
   * shut down on the router.
   *
   * Causes:
   *  * 403 Client Certificate Authentication Is Shutdown
   */
  CLIENT_CERTIFICATE_AUTHENTICATION_IS_SHUTDOWN: 52,
  //////////////////////
  // Guaranteed Messaging //
  //////////////////////
  /**
   * Guaranteed Messaging services are not enabled on the router.
   *
   * Causes:
   *  * 503 Service Unavailable
   */
  GM_UNAVAILABLE:                                100,
  /**
   * The session attempted to connect to a Guaranteed Message Publisher
   * that does not exist on this router.
   * All unacked messages held by the API are renumbered and redelivered. This subCode
   * is handled internally and will not be seen by the application. After successfully
   * renumbereing and redelivering the messages, if any messages are resent, listeners
   * on the @{link solace.SessionEventCode#event:REPUBLISHING_UNACKED_MESSAGES} event are
   * invoked.
   *
   * Causes:
   * * 400 Unknown Flow Name
   * @private
   */
  UNKNOWN_FLOW_NAME:                             111,
  /**
   * Already bound to the Queue or not authorized to bind to the Queue.
   *
   * Causes:
   *  * 400 Already Bound
   */
  ALREADY_BOUND:                                 112,
  /**
   * An attempt was made to bind to a Guaranteed Messaging Topic Endpoint with an
   * invalid topic.
   *
   * Causes:
   *  * 400 Invalid Topic Name
   */
  INVALID_TOPIC_NAME_FOR_TOPIC_ENDPOINT:         113,
  /**
   * An attempt was made to bind to an unknown Queue name (for example, not
   * configured on the router).
   *
   * Causes:
   *  * 503 Unknown Queue
   */
  UNKNOWN_QUEUE_NAME:                            114,
  /**
   * An attempt was made to perform an operation on an unknown Guaranteed Messaging
   * Topic Endpoint name (for example, not configured on router).
   *
   * Causes:
   *  * 503 Unknown Durable Topic Endpoint
   */
  UNKNOWN_TOPIC_ENDPOINT_NAME:                   115,
  /**
   * An attempt was made to bind to a Guaranteed Messaging Queue that has already reached
   * its maximum number of clients.
   *
   * Causes:
   *  * 503 Max clients exceeded for Queue
   */
  MAX_CLIENTS_FOR_QUEUE:                         116,
  /**
   * An attempt was made to bind to a Guaranteed Messaging Topic Endpoint that has already
   * reached its maximum number of clients.
   *
   * Causes:
   *  * 503 Max clients exceeded for durable Topic Endpoint
   */
  MAX_CLIENTS_FOR_TE:                            117,
  /**
   * An unexpected unbind response was received for a Guaranteed Messaging Queue or Topic
   * Endpoint (for example, the Queue or Topic Endpoint was deleted from the router).
   */
  UNEXPECTED_UNBIND:                             118,
  /**
   * The specified Guaranteed Messaging Queue was not found when publishing a message.
   *
   * Causes:
   *  * 400 Queue Not Found
   */
  QUEUE_NOT_FOUND:                               119,
  /**
   * Message was not delivered because the Guaranteed Message spool is over its
   * allotted space quota.
   *
   * Causes:
   *  * 503 Spool Over Quota
   */
  SPOOL_OVER_QUOTA:                              120,
  /**
   * An attempt was made to operate on a shutdown Guaranteed Messaging queue.
   *
   * Causes:
   *  * 503 Queue Shutdown
   */
  QUEUE_SHUTDOWN:                                121,
  /**
   * An attempt was made to operate on a shutdown Guaranteed Messaging Topic Endpoint.
   *
   * Causes:
   *  * 503 Durable Topic Endpoint Shutdown
   *  * 503 TE Shutdown
   *  * 503 Endpoint Shutdown
   */
  TOPIC_ENDPOINT_SHUTDOWN:                       122,
  /**
   * An attempt was made to bind to a non-durable Guaranteed Messaging Queue or Topic
   * Endpoint, and the router is out of resources.
   *
   * Causes:
   *  * 503 No More Non-Durable Queue or Topic Endpoint
   */
  NO_MORE_NON_DURABLE_QUEUE_OR_TOPIC_ENDPOINT:   123,
  /**
   * An attempt was made to create a Queue or Topic Endpoint that already exists.
   * This subcode is only returned if
   * {@link solace.SessionProperties.ignoreProvisionEndpointExists} was not set for the current
   * session.
   *
   * Causes:
   *  * 400 Endpoint Already Exists
   */
  ENDPOINT_ALREADY_EXISTS:                       124,
  /**
   * An attempt was made to delete or create a Queue or Topic Endpoint when the
   * Session does not have authorization for the action. This subcode is also returned when an
   * attempt is made to remove a message from an endpoint when the Session does not have 'consume'
   * authorization, or when an attempt is made to add or remove a Topic subscription from a Queue
   * when the Session does not have 'modify-topic' authorization.
   *
   * Causes:
   *  * 403 Permission Not Allowed
   */
  PERMISSION_NOT_ALLOWED:                        125,
  /**
   * An attempt was made to bind to a Queue or Topic Endpoint with an invalid
   * selector.
   *
   * Causes:
   *  * 400 Invalid Selector
   */
  INVALID_SELECTOR:                              126,
  /**
   * Publishing the message was denied due to exceeding the maximum spooled message
   * count.
   *
   * Causes:
   *  * 503 Max message usage exceeded
   */
  MAX_MESSAGE_USAGE_EXCEEDED:                    127,
  /**
   * An attempt was made to create a dynamic durable endpoint, and it was found to
   * exist with different properties.
   *
   * Causes:
   *  * 400 Endpoint Property Mismatch
   */
  ENDPOINT_PROPERTY_MISMATCH:                    128,
  /**
   * The client attempted to publish an Guaranteed Messaging message to a topic that
   * did not have any guaranteed subscription matches, or only matched a replicated topic.
   *
   * Causes:
   *  * 503 No Subscription Match
   */
  NO_SUBSCRIPTION_MATCH:                         129,
  /**
   * The application attempted to acknowledge a message that arrived via a delivery
   * mode that does not allow acknowledgements.
   */
  MESSAGE_DELIVERY_MODE_MISMATCH:                130,
  /**
   * The message was already acknowledged.
   */
  MESSAGE_ALREADY_ACKNOWLEDGED:                  131,
  /**
   * The API-supplied subscription did not match when attempting to bind to a
   * non-exclusive durable topic endoint.
   *
   * Causes:
   *  * 403 Subscription Does Not Match
   */

  SUBSCRIPTION_DOES_NOT_MATCH:                       133,
  /**
   * The API-supplied selector did not match when attempting to bind to a
   * non-exclusive durable topic endpoint.
   *
   * Causes:
   *  * 403 Selector Does Not Match
   */
  SELECTOR_DOES_NOT_MATCH:                           134,
  /**
   * The subscriber has provided an incorrectly formatted durable topic endpoint name.
   *
   * Causes:
   *  * 400 Invalid Durable Topic Endpoint Name
   */
  INVALID_DTE_NAME:                                  135,
  /**
   * The unsubscribe request was denied by the router because the durable topic endpoint
   * had one or more clients bound.
   *
   * Causes:
   *  * 400 Unsubscribe Not Allowed, Client(s) Bound To DTE
   */
  UNSUBSCRIBE_NOT_ALLOWED_CLIENTS_BOUND:             136,
  /**
   * An application callback threw an error back to the API. The reason property describes
   * the error that occurred.
   */
  CALLBACK_ERROR:                                    137,
  /**
   * A published message was discarded by the router because it will not be published
   * anywhere based on the NoLocal properties. This can be considered normal.
   *
   * Causes:
   * * 400 Nolocal Discard
   */
  NOLOCAL_DISCARD:                                   138,
  /**
   * The operation is delayed because Guaranteed Messaging is not ready on the router.
   *
   * Causes:
   * 507 Ad Not Ready
   */
  GM_NOT_READY:                                      140,
  /**
   * The message was rejected because one or more matching endpoints'
   * reject-low-priority-msg-limit was exceeded.
   *
   * Causes:
   * * 503 Low Priority Msg Congestion
   */
  LOW_PRIORITY_MSG_CONGESTION:                       141,
  /**
   * The specified endpoint quota was out of range.
   *
   * Causes:
   * 400 Quota Out Of Range
   */
  QUOTA_OUT_OF_RANGE:                                142,
  /**
   * Unable to load the certificate from the TrustStore for a SSL
   * secured session.
   */
  FAILED_LOADING_TRUSTSTORE:                         143,
  /**
   * The client failed to load certificate and/or private key files.
   */
  FAILED_LOADING_CERTIFICATE_AND_KEY:                144,
  /**
   * DNS resolution failed for all hostnames.
   */
  UNRESOLVED_HOSTS:                                  145,
  /**
   * Replay is not supported on the Solace Message Router
   */
  REPLAY_NOT_SUPPORTED:                              146,
  /**
   *  Replay is not enabled in the message-vpn
   */
  REPLAY_DISABLED:                                   147,
  /**
   *  The client attempted to start replay on a flow bound to a non-exclusive endpoint
   */
  CLIENT_INITIATED_REPLAY_NON_EXCLUSIVE_NOT_ALLOWED: 148,
  /**
   *  The client attempted to start replay on an inactive flow
   */
  CLIENT_INITIATED_REPLAY_INACTIVE_FLOW_NOT_ALLOWED: 149,
  /**
   *  N/A - Browser Flows are not supported.
   */
  CLIENT_INITIATED_REPLAY_BROWSER_FLOW_NOT_ALLOWED:  150,
  /**
   *  Replay is not supported on temporary endpoints
   * @deprecated since 10.3.1 - Replay is now supported on Temp Endpoints
   */
  REPLAY_TEMPORARY_NOT_SUPPORTED:                    151,
  /**
   *  The client attempted to start a replay but provided an unknown start location type.
   */
  UNKNOWN_START_LOCATION_TYPE:                       152,
  /**
   *  A replay in progress on a flow was administratively cancelled, causing the flow to be unbound
   */
  REPLAY_CANCELLED:                                  153,
  /**
   *  A replay in progress on a flow failed because messages to be replayed were trimmed
   *  from the replay log
   */
  REPLAY_MESSAGE_UNAVAILABLE:                        154,
  /**
   *  A replay was requested but the requested start time is not available in the replay log
   */
  REPLAY_START_TIME_NOT_AVAILABLE:                   155,
  /**
   *  The Solace Message Router attempted to replay a message, but the queue/topic
   *  endpoint rejected the message to the sender
   */
  REPLAY_MESSAGE_REJECTED:                           156,
  /**
   *  A replay in progress on a flow failed because the replay log was modified
   */
  REPLAY_LOG_MODIFIED:                               157,
  /**
   * Endpoint error ID in the bind request does not match the endpoint's error ID.
   */
  MISMATCHED_ENDPOINT_ERROR_ID:                      158,
  /**
   * A replay was requested, but the router does not have sufficient resources
   * to fulfill the request, due to too many active replays.
   */
  OUT_OF_REPLAY_RESOURCES:                           159,

  /**
   * A replay was in progress on a Durable Topic Endpoint (DTE)
   * when its topic or selector was modified, causing the replay to fail.
   */
  TOPIC_OR_SELECTOR_MODIFIED_ON_DURABLE_TOPIC_ENDPOINT:         160,
  /**
   *  A replay in progress on a flow failed
   */
  REPLAY_FAILED:                                                161,
  /**
   *  A replay was started on the queue or DTE, either by another client or by the router.
   */
  REPLAY_STARTED:                                               162,
  /**
   * Router does not support Compressed TLS
   */
  COMPRESSED_TLS_NOT_SUPPORTED:                                 163,
  /**
   * The client attempted to add a shared subscription, but the capability is not supported
   * by the appliance.
   */
  SHARED_SUBSCRIPTIONS_NOT_SUPPORTED:                           164,
  /**
   * The client attempted to add a shared subscription on a client that is not permitted to
   * use shared subscriptions.
   */
  SHARED_SUBSCRIPTIONS_NOT_ALLOWED:                             165,
  /**
   * The client attempted to add a shared subscription to a queue or topic endpoint.
   */
  SHARED_SUBSCRIPTIONS_ENDPOINT_NOT_ALLOWED:                    166,
  /**
   * A replay was requested but the requested start message is not available in the replay log.
   */
  REPLAY_START_MESSAGE_NOT_AVAILABLE:                           167,
  /**
   * Replication Group Message Id are not comparable.
   * Messages must be published to the same broker or HA pair for their Replicaton Group
   * Message Id to be comparable.
   */
  MESSAGE_ID_NOT_COMPARABLE:                                    168,
  /**
   * Replay is not supported on anonymous queue
   * - 403 Replay Not Supported on Anonymous Queue
   */
  REPLAY_ANONYMOUS_QUEUE_NOT_SUPPORTED:                         169,
  /**
   * Browser flows to Partitioned Queues are not permitted.
   * - 403 Browsing Not Supported on Partitioned Queue
   */
  PARTITIONED_QUEUE_BROWSING_NOT_SUPPORTED:                     170,
  /**
   * Egress selectors are not permitted when binding to a Partitioned Queue.
   * - 403 Selectors Not Supported on Partitioned Queue
   */
  PARTITIONED_QUEUE_SELECTORS_NOT_SUPPORTED:                    171,
  /**
   * Replay Not Supported on Anonymous Queue Without Subscriptions
   * - 403 Replay Not Supported on Anonymous Queue Without Subscriptions
   */
  REPLAY_ANONYMOUS_QUEUE_WITHOUT_SUBSCRIPTIONS_NOT_SUPPORTED:   172,
  /**
   * The message was rejected because the appliance has been configured
   * to reject messages when sync replication mode is ineligible.
   * - 503 Sync Replication Ineligible (Reject Messages when Synchronous Replication Mode is Ineligible)
   * 
   * * The transaction was started as a synchronous replicated transaction,
   * but replication became ineligible during the transaction.
   * - 503 Sync Replication Ineligible (Replication Ineligible during Synchronous Replicated Transaction)
   */
  SYNC_REPLICATION_INELIGIBLE:                                  173,
  /**
   * An attempt was made to bind to a durable TE or Queue 
   * with a flow property of ‘non-durable’.
   *
   * Causes:
   *  * 400 Invalid Queue or Topic Endpoint Durability
   */
  INVALID_QUEUE_OR_TOPIC_ENDPOINT_DURABILITY:                   174,
};

module.exports.ErrorSubcode = Enum.new(ErrorSubcode);
