const {
  ErrorSubcode,
  OperationError,
} = require('solclient-error');
const { FactoryProfile, SolclientFactoryProfiles } = require('./solclient-factory-profiles');
const { Parameter } = require('solclient-validate');
const { ProfileBinding } = require('./profile-binding');
const { SolclientFactoryProperties } = require('./solclient-factory-properties');

const factoryState = {
  initializeCount: 0,
  initializers:    [],
};
/**
 * @global
 * @name solClientJS
 * @description
 * <b>solClientJS</b> is a full functioned Solace Messaging API written entirely in JavaScript.
 * Applications using the <i>solClientJS</i> API can access all the features of a
 * Solace Message Router.
 *
 * The API is intended for use by applications written in JavaScript, targetting
 * either <b>NODE</b> or a traditional web browser.
 *
 * All classes, objects, methods of the API are encapsulated in the {@link solace} namespace.
 * The starting point for all applications is {@link solace.SolclientFactory}. This factory object
 * generates the {@link solace.Session} for connecting to the Solace Message Router.
 * {@link solace.SolclientFactory} also generates the {@link solace.Message} object
 * which enncapsulates the messages and {@link solace.Destination} the application will use
 * to send and receive data.
 * @summary The Solace Message Router Messaging API for Javascript.
 * @importTypeDefinitions import {EventEmitter} from 'events';
 * @importTypeDefinitions import Long = require('long');
 */

/**
 * A singleton used as the main factory for the messaging APIs. The very first operation by
 * any application must be to initialize the API:
 * * {@link solace.SolclientFactory.init}
 *
 * <i>SolclientFactory</i> provides methods to construct:
 * * {@link solace.Session}
 * * {@link solace.Message}
 * * {@link solace.Destination}
 *
 * Additionally <i>SolclientFactory</i> manages the
 * logging level in the API.
 * @namespace
 * @public
 * @memberof solace
 */
const SolclientFactory = {

  /**
   * Adds a function to be called on factory initialization
   * @param {function(factoryProps, factoryState)} func initializer function to be called
   * @internal
   */
  addInitializer(func) {
    factoryState.initializers.push(func);
  },

  /**
   * @param {function} func function to wrap
   * @returns {function} new factory method
   * @internal
   */
  createFactory(func) {
    return function factoryMethod(...args) {
      if (factoryState.initializeCount === 0) {
        throw new OperationError('SolclientFactory not initialized', ErrorSubcode.INVALID_OPERATION);
      }
      return func(...args);
    };
  },

  /**
   * Initialize global properties. This function must be called before any other API call is made.
   *
   * Note: After the first call to this method, subsequent calls have no effect.
   *
   * @param {solace.SolclientFactoryProperties} [factoryProps] The initialization properties for
   *  the factory, if required.
   * @param {solace.LogImpl} [factoryProps.logger] A logging implementation
   * @param {solace.LogLevel}[factoryProps.logLevel] The logging level to use
   *  for filtering log events.
   * @param {solace.SolclientFactoryProfiles}
   *  [factoryProps.profile=solace.SolclientFactoryProfiles.version7]
   *     The factory profile. This class cannot be created by an API user; choose one of the static
   *     instances from {@link solace.SolclientFactoryProfiles}.
   * @throws {solace.OperationError} Invalid logger implementation
   * @returns {solace.SolclientFactory} For method chaining
   */
  init(factoryProps) {
    if (factoryState.initializeCount > 0) {
      return this;
    }

    const props = new SolclientFactoryProperties(factoryProps);
    const profile = factoryProps && factoryProps.profile || SolclientFactoryProfiles.version7;
    Parameter.isInstanceOf('factoryProps.profile', profile, FactoryProfile);
    ProfileBinding.value = profile;

    factoryState.initializers.forEach((initializer) => {
      initializer.call(this, props, factoryState);
    });
    ++factoryState.initializeCount;

    if ((factoryProps !== undefined) && (factoryProps !== null)) {
      // Factory should not depend on other packages at file scope
      // eslint-disable-next-line global-require
      const { LOG_DEBUG } = require('solclient-log');
      LOG_DEBUG('Factory properties:\n', factoryProps);
    }
    return this;
  },

  /**
   * @private
   */
  reset() {
    factoryState.initializeCount = 0;
  },


  /**
   * @returns {Number} Count of factory initializations.
   *    Nonzero means cannot be initialized again.
   * @internal
   */
  _getInitializeCount() {
    return factoryState.initializeCount;
  },

  /**
   * @private
   * @name solace.SolclientFactory.profiles
   * @type {solace.SolclientFactoryProfiles}
   * @readonly
   * @description The collection of {@link solace.FactoryProfile}. See a description of
   * each in {@link solace.SolclientFactoryProfiles}.
   */
  get profiles() {
    return SolclientFactoryProfiles;
  },
};

module.exports.SolclientFactory = SolclientFactory;
