const { ErrorSubcode,
        OperationError,
        RequestError,
        RequestEventCode } = require('solclient-error');
const { SessionEventCode } = require('./session-event-codes');

function buildType(Superclass) {
  /**
   * @classdesc
   * Represents a session event; events are passed to the application-provided
   * event handling callback provided when creating the session.
   * @memberof solace
   * @hideconstructor
   */
  class SessionEvent extends Superclass {
    /**
     * @constructor
     * @param {Array} superclassArgs Args to pass to super
     * @param {solace.SessionEventCode} sessionEventCode The event code
     * @param {String} infoStr Information string
     * @param {Number} [responseCode] Any associated router response code
     * @param {solace.ErrorSubcode} [errorSubcode] Any associated error subcode
     * @param {Object|String|null|undefined} [correlationKey] Any associated correlation key
     * @param {String} [reason] Any additional information
     * @private
     */
    constructor(superclassArgs,
                sessionEventCode,
                infoStr,
                responseCode = undefined,
                errorSubcode = 0,
                correlationKey = undefined,
                reason = undefined) {
      super(...superclassArgs);
      this._sessionEventCode = sessionEventCode;
      this._infoStr = infoStr;
      this._responseCode = responseCode;
      this._errorSubcode = errorSubcode;
      this._correlationKey = correlationKey; // optional
      this._reason = reason; // optional
    }

    /**
     * @type {solace.SessionEventCode}
     * @description Further qualifies the session event.
     */
    get sessionEventCode() {
      return this._sessionEventCode;
    }

    /**
     * @type {String}
     * @description if applicable, an information string returned by the Solace Message Router.
     */
    get infoStr() {
      return this._infoStr;
    }

    /**
     * @type {?Number}
     * @description if applicable, a response code returned by the Solace Message Router.
     */
    get responseCode() {
      return this._responseCode;
    }

    /**
     * @type {?solace.ErrorSubcode}
     * @description if applicable, an error subcode. Defined in {@link solace.ErrorSubcode}
     */
    get errorSubcode() {
      // _eslint-disable-next-line quote-property
      return this.subcode || this._errorSubcode;
    }

    /**
     * @deprecated Use {@link solace.SessionEvent#errorSubcode} instead.
     * @readonly
     */
    // coverity[identifier_typo]
    get errorSubCode() {
      return this.errorSubcode;
    }

    /**
     * @type {?Object}
     * @description A user-specified object
     * made available in the response or confirmation event by including it as a
     * parameter in the orignal API call.  If the user did not specify a
     * correlationKey, it will be <code>null</code>.
     * @default null
     */
    get correlationKey() {
      return this._correlationKey;
    }

    /**
     * @type {?String}
     * @description Additional information if it is applicable.
     * In case of subscribe or publish errors, it constains the topic.
     */
    get reason() {
      return this._reason;
    }
    /**
     * @param {Object} value The reason info object
     * @internal
     */
    set reason(value) {
      this._reason = value;
    }

    [util_inspect_custom]() {
      // Is this supposed to invoke the custom inspect function of the superclass if it exists?
      return Object.assign(super[util_inspect_custom] || {}, {
        'sessionEventCode': SessionEventCode.describe(this.sessionEventCode),
        'infoStr':          this.infoStr,
        'responseCode':     this.responseCode,
        'errorSubcode':     ErrorSubcode.describe(this.errorSubcode),
        'correlationKey':   this.correlationKey ? this.correlationKey.toString() : null,
        'reason':           this.reason ? this.reason : null,
      });
    }

    toString() {
      return util_inspect(this);
    }
  }

  return SessionEvent;
}

const SUPERCLASS_FOR_SESSION_EVENT = {
  [SessionEventCode.CONNECT_FAILED_ERROR]:              OperationError,
  [SessionEventCode.DOWN_ERROR]:                        OperationError,
  [SessionEventCode.GUARANTEED_MESSAGE_PUBLISHER_DOWN]: OperationError,
  [SessionEventCode.PROPERTY_UPDATE_ERROR]:             RequestError,
  [SessionEventCode.REJECTED_MESSAGE_ERROR]:            RequestError,
  [SessionEventCode.SUBSCRIPTION_ERROR]:                RequestError,
  [SessionEventCode.UNSUBSCRIBE_TE_TOPIC_ERROR]:        OperationError,
  [RequestEventCode.REQUEST_ABORTED]:                   RequestError,
  [RequestEventCode.REQUEST_TIMEOUT]:                   RequestError,
};

const SUPERCLASS_ARGS = new Map()
  .set(Object, () => [])
  .set(OperationError, (sec, infoStr, rc, subcode, ck, reason) => [infoStr, subcode, reason])
  .set(RequestError, (sec, infoStr, rc, subcode, ck, reason) => [infoStr, sec, ck, reason]);

const TYPE_CACHE = new Map();

function build(sessionEventCode,
               infoStr,
               responseCode = undefined,
               errorSubcode = 0,
               correlationKey = undefined,
               reason = undefined) {
  const Superclass = SUPERCLASS_FOR_SESSION_EVENT[sessionEventCode] || Object;
  const SessionEvent = (() => {
    let result = TYPE_CACHE.get(Superclass);
    if (result) return result;
    result = buildType(Superclass);
    TYPE_CACHE.set(Superclass, result);
    return result;
  })();
  const superclassArgsBuilder = (SUPERCLASS_ARGS.get(Superclass) || (() => []));
  const superclassArgs = superclassArgsBuilder(sessionEventCode,
                                               infoStr,
                                               responseCode,
                                               errorSubcode,
                                               correlationKey,
                                               reason);
  return new SessionEvent(superclassArgs,
                          sessionEventCode,
                          infoStr,
                          responseCode,
                          errorSubcode,
                          correlationKey,
                          reason);
}

// Expose a type
const SessionEvent = buildType(Object);
SessionEvent.build = build;

module.exports.SessionEvent = SessionEvent;
