const {
  ErrorSubcode,
  OperationError,
} = require('solclient-error');
const {
  HTTPTransportSession,
} = require('./http');
const {
  LOG_TRACE,
  LOG_INFO,
  LOG_ERROR,
} = require('solclient-log');
const { FsmEvent } = require('solclient-fsm');
const { TransportBase } = require('../transport-base');
const { TransportProtocol } = require('../transport-protocols');
const { TransportProtocolHandler } = require('../transport-protocol-handler');
const { TransportReturnCode } = require('../transport-return-codes');
const { TransportSessionEventCode } = require('../transport-session-event-codes');
const { WebSocketTransportSession } = require('./websocket-transport-session');
const { WebTransportEvent } = require('./web-transport-events');
const { WebTransportFSM } = require('./web-transport-fsm');

/**
 * @classdesc
 * This class manages all the web based transport protocols.
 * <ul>
 *     <li>{@link TransportProtocol.HTTP_BASE64}
 *     <li>{@link TransportProtocol.HTTP_BINARY}
 *     <li>{@link TransportProtocol.HTTP_BINARY_STREAMING}
 *     <li>{@link TransportProtocol.WS_BINARY}
 * </ul>
 * @extends TransportBase
 * @private
 */
class WebTransport extends TransportBase {

  /**
   * @constructor
   * @param {URL} url The url to connect to
   * @param {Function} eventCB The callback for transport events
   * @param {BaseSMFClient} client An SMF client instance
   * @param {Object} props Additional transport properties
   * @param {Function} getId A function that returns this transport's unique ID
   */
  constructor(url, eventCB, client, props, getId) {
    super(url, eventCB, client, props);
    LOG_TRACE(`webTransportProtocolList ${props.webTransportProtocolList}`);
    this._transportHandler = new TransportProtocolHandler(url, props.webTransportProtocolList);
    this._webTransportFsm = new WebTransportFSM(this, getId);
    this._webTransportFsm.start();
  }

  notifyEvent(event) {
    this._eventCB(event);
  }

  handleDestroyed() {
    this._transportSession = null;
  }

  handleTransportEvent(transportEvent) {
    LOG_INFO(`Web transport receive transport event: ${transportEvent}`);
    let wEvent;
    switch (transportEvent.getTransportEventCode()) {
      case TransportSessionEventCode.UP_NOTICE:
        wEvent = new FsmEvent({ name: WebTransportEvent.UP_NOTICE });
        wEvent._transportEvent = transportEvent;
        this._webTransportFsm.processEvent(wEvent);
        break;

      case TransportSessionEventCode.DESTROYED_NOTICE:
        this.handleDestroyed();
        wEvent = new FsmEvent({ name: WebTransportEvent.DESTROYED_NOTICE });
        wEvent._transportEvent = transportEvent;
        this._webTransportFsm.processEvent(wEvent);
        break;

      case TransportSessionEventCode.SEND_ERROR:
        wEvent = new FsmEvent({ name: WebTransportEvent.SEND_ERROR });
        wEvent._transportEvent = transportEvent;
        this._webTransportFsm.processEvent(wEvent);
        break;

      case TransportSessionEventCode.CONNECT_TIMEOUT:
        wEvent = new FsmEvent({ name: WebTransportEvent.CONNECT_TIMEOUT });
        wEvent._transportEvent = transportEvent;
        this._webTransportFsm.processEvent(wEvent);
        break;

      case TransportSessionEventCode.DOWNGRADE_FAILED:
        this._lastDowngradeSucceeded = false;
        break;

      case TransportSessionEventCode.DOWNGRADE_SUCCEEDED:
        this._lastDowngradeSucceeded = true;
        break;

      default:
        // All other transport events have no effect on the web transport and are passed through
        this._eventCB(transportEvent);
    }
  }

  /**
   * @override
   */
  connect() {
    const wEvent = new FsmEvent({ name: WebTransportEvent.CONNECT });
    this._webTransportFsm.processEvent(wEvent);
    return TransportReturnCode.OK;
  }

  connectInternal() {
    this._transportSession = null;
    const tpProtocol = this._transportHandler.getTransportProtocol();
    this._props.transportProtocol = tpProtocol;
    switch (tpProtocol) {
      case TransportProtocol.HTTP_BASE64:
      case TransportProtocol.HTTP_BINARY:
      case TransportProtocol.HTTP_BINARY_STREAMING:
        this._transportSession = new HTTPTransportSession(
          this._url,
          evt => this.handleTransportEvent(evt),
          this._client,
          this._props
        );
        break;

      case TransportProtocol.WS_BINARY:
        this._transportSession = new WebSocketTransportSession(
          this._url,
          evt => this.handleTransportEvent(evt),
          this._client,
          this._props
        );
        break;

      default:
        LOG_ERROR(`Web transport unrecognized TransportProtocol: ${tpProtocol}`);
        throw new OperationError(`No transport session provider for scheme: ${tpProtocol}`,
                                 ErrorSubcode.CONNECTION_ERROR,
                                 tpProtocol);
    }

    LOG_INFO(`Connect Transport ${tpProtocol}`);
    return this._transportSession.connect();
  }

  /**
   * @override
   */
  destroy(msg, subcode) {
    const wEvent = new FsmEvent({ name: WebTransportEvent.DESTROY });
    wEvent._destroyMsg = msg;
    wEvent._subcode = subcode;
    this._webTransportFsm.processEvent(wEvent);
    return TransportReturnCode.OK;
  }

  /**
   * Force fail the underlying transport's socket to force a failure.
   * Returns transport return code.
   * @override
   * @param {?String} msg The message associated with this operation, if any.
   * @returns {TransportReturnCode} The result of this operation
   */
  forceFailure(msg) {
    const errorMsg = (msg !== undefined && msg !== null) ? msg : ''; // default empty
    LOG_DEBUG(`Destroy Tls transport: ${errorMsg}`);
    if (this._transportSession) {
      this._transportSession._socket._sender._socket.destroy(new Error(errorMsg));
    }
    return TransportReturnCode.OK;
  }

  /**
   * @param {String} msg The message associated with the downgrade
   * @param {ErrorSubcode} subcode The subcode associated with the downgrade
   * @returns {Boolean} `true` if there are downgrade options available. `false` otherwise.
   * @private
   */
  beginDowngrade(msg, subcode) {
    if (this._transportHandler.canCompleteDowngrade()) {
      LOG_TRACE('Web transport downgrade');
      // Destroy the underlying transport session
      // This will asynchronously cause DESTROYED_NOTICE to be emitted.
      // The receiver can then call completeDowngrade, which will complete the downgrade.
      this.destroyInternal(msg, subcode);
      return true;
    }
    LOG_TRACE('Web transport downgrade rejected');
    return false;
  }

  completeDowngrade() {
    if (!this._transportHandler.canCompleteDowngrade()) {
      return false;
    }
    return this._transportHandler.completeDowngrade();
  }

  destroyInternal(msg, subcode) {
    if (this._transportSession) {
      this._transportSession.destroy(msg, subcode);
    }
  }

  /**
   * @override
   */
  flush(callback) {
    return this._transportSession.flush(callback);
  }

  getConnError() {
    if (this._transportSession) {
      return this._transportSession._connError;
    }
    return null;
  }

  /**
   * @override
   */
  getInfoStr() {
    if (this._transportSession) {
      return this._transportSession.getInfoStr();
    }
    return 'Not connected.';
  }

  /**
   * @override
   */
  getTransportProtocol() {
    return this._transportHandler.getTransportProtocol();
  }

  /**
   * @override
   */
  getClientStats() {
    if (this._transportSession) {
      return this._transportSession.getClientStats();
    }
    return null;
  }

  /**
   * @override
   */
  requestDowngrade(msg, subcode) {
    LOG_TRACE('Creating downgrade request event');
    this._lastDowngradeSucceeded = undefined; // will be reset by handler for TS DOWNGRADE_ events
    const wEvent = new FsmEvent({ name: WebTransportEvent.DOWNGRADE });
    wEvent._downgradeMsg = msg;
    wEvent._subcode = subcode;
    this._webTransportFsm.processEvent(wEvent);
    return this._lastDowngradeSucceeded;
  }

  /**
   * @override
   */
  send(message, forceAllowEnqueue) {
    return this._transportSession.send(message, forceAllowEnqueue);
  }
}

module.exports.WebTransport = WebTransport;
