class BaseSMFClient {
  constructor(rxSmfCB, rxMessageErrorCB, session) {
    this.reset();
    this._rxSmfCB = rxSmfCB; // callback for parsed msg
    this._rxMessageErrorCB = rxMessageErrorCB; // invalid UH, etc.
    this._session = session;
  }

  reset() {
    this._correlationCounter = 0;
  }

  nextCorrelationTag() {
    // Login uses the max value as a sentinel, so wrap around early
    if (++this._correlationCounter >= BaseSMFClient.SMF_MAX_CORRELATION) {
      this._correlationCounter = 1;
    }
    // added check to prevent it returning as undefined/null
    return this._correlationCounter ? this._correlationCounter : 0;
  }
}

BaseSMFClient.SMF_MAX_CORRELATION = 0xFFFFFF;


module.exports.BaseSMFClient = BaseSMFClient;
