const { Enum } = require('solclient-eskit');

/**
 * Represents an enumeration of supported message settlement outcomes.
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const MessageOutcome = {
  /**
   * Represents a successful message processing acknowledgement outcome.
   *
   * (see {@link solace.Message#acknowledge}) for more details
   */
  ACCEPTED:         0,
  /**
   * Represents a negative acknowledgement outcome, used to signal that the application failed to process
   * the message.
   * 
   * Delivery count for the message is incremented. Redelivery will be attempted. Message may
   * be moved to DMQ once max-redelivered is reached. Message may be delayed if the endpoint has
   * delayed redelivery configured.
   */
  FAILED:           1,
  // /**
  //  * Represents a negative acknowledgement outcome, used to signal that the application failed to process
  //  * the message.
  //  * 
  //  * Delivery count for the message is NOT incremented. Redelivery will be attempted. Message may
  //  * be moved to DMQ once max-redelivered is reached. 
  //  * A released message will always be eligible for redelivery, even if redeliveries are disabled. 
  //  * Message is immediately eligible for redelivery, even if endpoint is configured for delayed redelivery.
  //  */
  // RELEASED:           2,
  /**
   * Represents a negative acknowledgement outcome, used to signal that the application has rejected the
   * message such as when application determines the message is invalid.
   * 
   * Message will NOT be redelivered. Message will be moved to DMQ. If DMQ is not configured,
   * message is deleted.
   */
  REJECTED:         3,
};

module.exports.MessageOutcome = Enum.new(MessageOutcome);
