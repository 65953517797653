const { ErrorSubcode } = require('solclient-error');


/**
 * Message consumer event objects. A {@link solace.MessageConsumer} will emit
 * these events related to queue subscription management:
 * MessageConsumerEventName.SUBSCRIPTION_OK and
 * MessageConsumerEventName.SUBSCRIPTION_ERROR.
 *
 * Similar to SessionEvent.
 * Also compatible with RequestError.
 *
 * @memberof solace
 */
class MessageConsumerEvent {
  /**
   * @constructor
   * @param {String} messageConsumerEventName one of the MessageConsumerEventNames.
   * @param {String} infoStr Information string
   * @param {Number} [responseCode] Any associated router response code
   * @param {solace.ErrorSubcode} [errorSubcode] Any associated error subcode
   * @param {Object|String|null|undefined} [correlationKey] Any associated correlation key
   * @param {String} [reason] Any additional information
   * @private
   */
  constructor(
    messageConsumerEventName,
    infoStr,
    responseCode = undefined,
    errorSubcode = 0,
    correlationKey = undefined,
    reason = undefined) {
    this._messageConsumerEventName = messageConsumerEventName;
    this._infoStr = infoStr;
    this._responseCode = responseCode;
    this._errorSubcode = errorSubcode;
    this._correlationKey = correlationKey; // optional
    this._reason = reason; // optional
  }

  /**
   * @type {String}
   * @description the appropriate MessageConsumerEventName
   *
   *   MessageConsumerEventName.SUBSCRIPTION_OK or
   *   MessageConsumerEventName.SUBSCRIPTION_ERROR.
   */
  get messageConsumerEventName() {
    return this._messageConsumerEventName;
  }

  /**
   * @type {String}
   * @description the appropriate MessageConsumerEventName
   *
   *   MessageConsumerEventName.SUBSCRIPTION_OK or
   *   MessageConsumerEventName.SUBSCRIPTION_ERROR.
   */
  get name() {
    return this._messageConsumerEventName;
  }

  /**
   * @type {String}
   * @description if applicable, an information string returned by the Solace Message Router.
   */
  get infoStr() {
    return this._infoStr;
  }

  /**
   * @type {?Number}
   * @description if applicable, a response code returned by the Solace Message Router.
   */
  get responseCode() {
    return this._responseCode;
  }

  /**
   * @type {?solace.ErrorSubcode}
   * @description if applicable, an error subcode. Defined in {@link solace.ErrorSubcode}
   * same as subcode.
   */
  get errorSubcode() {
    return this._errorSubcode;
  }

  /**
   * @type {?solace.ErrorSubcode}
   * @description if applicable, an error subcode. Defined in {@link solace.ErrorSubcode}
   * Same as errorSubcode.
   */
  get subcode() {
    return this._errorSubcode;
  }

  /**
   * @type {?Object}
   * @description A user-specified object
   * made available in the response or confirmation event by including it as a
   * parameter in the orignal API call.  If the user did not specify a
   * correlationKey, it will be <code>null</code>.
   * @default null
   */
  get correlationKey() {
    return this._correlationKey;
  }

  /**
   * @type {?String}
   * @description Additional information if it is applicable.
   *
   * In case of subscribe or publish events, it constains the topic.
   */
  get reason() {
    return this._reason;
  }
  /**
   * @param {Object} value The reason info object
   * @internal
   */
  set reason(value) {
    this._reason = value;
  }

  /**
   * @type {?solace.SessionEventCode}
   * @description Only here for compatibility with the RequestError exception class.
   * Always returns undefined for a MessageConsumerEvent.
   */
  // eslint-disable-next-line class-methods-use-this
  get requestEventCode() {
    return undefined;
  }

  [util_inspect_custom]() {
    return {
      'messageConsumerEventName': this.messageConsumerEventName,
      'infoStr':                  this.infoStr,
      'responseCode':             this.responseCode,
      'errorSubcode':             ErrorSubcode.describe(this.errorSubcode),
      'correlationKey':           this.correlationKey ? this.correlationKey.toString() : null,
      'reason':                   this.reason ? this.reason : null,
    };
  }

  toString() {
    return util_inspect(this);
  }
}

module.exports.MessageConsumerEvent = MessageConsumerEvent;
