const { Enum } = require('solclient-eskit');

/**
 * Represents an enumeration of peer capabilities.
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const CapabilityType = {
  /**
   * Peer's software load version. Type: string.
   */
  PEER_SOFTWARE_VERSION:        0,
  /**
   * Peer's software release date. Type: string.
   */
  PEER_SOFTWARE_DATE:           1,
  /**
   * Peer's platform. Type: string.
   */
  PEER_PLATFORM:                2,
  /**
   * Speed (in Mbps) of the port the client connects to. Type: number.
   */
  PEER_PORT_SPEED:              3,
  /**
   * Type of the port the client has connected to (currently 0: Ethernet). Type: number.
   */
  PEER_PORT_TYPE:               4,
  /**
   * Maximum size of a Direct message (in bytes), including all optional message headers and data.
   * Type: number.
   */
  MAX_DIRECT_MSG_SIZE:          5,
  /**
   * Peer's router name. Type: string.
   *
   * This property is useful when sending SEMP requests to a peer's SEMP topic, which may be
   * constructed as `#P2P/routername/#client/SEMP`.
   */
  PEER_ROUTER_NAME:             6,
  /**
   * Peer supports message eliding. Type: boolean.
   */
  MESSAGE_ELIDING:              7,
  /**
   * Peer supports NoLocal option (client may avoid receiving messages published by itself).
   */
  NO_LOCAL:                     8,
  /**
   * Peer supports Guaranteed Message Consumer connections for receiving guaranteed messages.
   */
  GUARANTEED_MESSAGE_CONSUME:   9,
  /**
   * Peer supports temporary endpoints.
   */
  TEMPORARY_ENDPOINT:           10,
  /**
   * Peer supports Guaranteed Message Publisher connections for sedning guaranteed messages.
   */
  GUARANTEED_MESSAGE_PUBLISH:   11,
  /**
   * Peer supports Guaranteed Messages Browser connections for receiving guaranteed messages
   */
  GUARANTEED_MESSAGE_BROWSE:    12,
  /**
   * Peer supports creating/modify/disposing endpoints.
   */
  ENDPOINT_MGMT:                13,
  /**
   * Peer supports selectors on Guaranteed Message Consumers.
   */
  SELECTOR:                     14,
  /**
   * Maximum size of a Direct message (in bytes), including all optional message headers and data.
   * Type: number.
   */
  MAX_GUARANTEED_MSG_SIZE:      15,
  /**
   * Peer supports Guaranteed Messaging Consumer state change updates. Type: boolean
   */
  ACTIVE_CONSUMER_INDICATION:   16,
  /**
   * Peer accepts compressed (DEFLATE) data. Type: boolean.
   */
  COMPRESSION:                  17,
  /**
   * Peer supports Guaranteed Messaging cut-through. Type: boolean
   * @deprecated since broker v9.10.0.12
   */
  CUT_THROUGH:                  18,
  /**
   * Peer supports provisioned queue and topic-endpoint discard behavior. Type: boolean
   */
  ENDPOINT_DISCARD_BEHAVIOR:    19,
  /**
   * Peer supports Guaranteed Messaging message TTL and Dead-Message Queues. Type: boolean
   */
  ENDPOINT_MESSAGE_TTL:         20,
  /**
   * Peer accepts JNDI queries. Type: boolean.
   */
  JNDI:                         21,
  /**
   * Peer supports per topic sequence numbering for Guaranteed Messaging messages. Type: boolean
   */
  PER_TOPIC_SEQUENCE_NUMBERING: 22,
  /**
   * Peer supports QueueSubscriptionAdd for managing subscriptions on queue endpoints.
   * Type: boolean
   */
  QUEUE_SUBSCRIPTIONS:          23,
  /**
   * Peer supports add/remove subscriptions for a specified clientName. Type: boolean
   */
  SUBSCRIPTION_MANAGER:         24,
  /**
   * Peer supports transacted sessions. Type: boolean.
   */
  TRANSACTED_SESSION:           25,
  /**
   * Peer support Message Replay. Type: boolean.
   */
  MESSAGE_REPLAY:               26,
  /**
   * Peer supports TLS downgrade to compression (encrypted and plaintext) Type: boolean
   */
  COMPRESSED_SSL:               27,
  /**
   * The peer can support \#share and \#noexport subscriptions
   * Type: Boolean
   */
  SHARED_SUBSCRIPTIONS:         28,
  /**
   * The EndpointErrorId in replay bind responses can be trusted.
   */
  BR_REPLAY_ERRORID:            29,
  /**
   * Support for ApplicationAck optional outcomes "failed" and "rejected".
   */
  AD_APP_ACK_FAILED:            30,
  /**
   * Broker supports variable-length SMF extended parameters.
   */
  VAR_LEN_EXT_PARAM:            31,
};

/**
 * Represents an enumeration of client capabilities.
 * These are sent in the ClientCtrl login messages.
 *
 * @enum {number}
 * @namespace
 * @memberof solace
 */
const ClientCapabilityType = {
  /**
   * Client implements acknowledgements to router unsolicited unbinds.
   * Always true.
   */
  UNBIND_ACK: 0,
  /**
   * Indicates whether a client will process an EndpointErrorId in a BindResponse message.
   * Always true
   */
  BR_ERRORID: 1,
  /**
   * Indicates whether a client supports Partitioned Queues
   * and whether a PartitionGroupId will be proccessed in a BindResponse message.
   * Always true
   * 
   * @since 10.12.0
   */
   PQ: 2,
};
module.exports.CapabilityType = Enum.new(CapabilityType);
module.exports.ClientCapabilityType = Enum.new(ClientCapabilityType);
