
const { LogFormatter } = require('solclient-log');
const { Baggage } = require('./baggage');
const { TraceContext } = require('./message-trace-context');
const { TraceContextSetter } = require('./message-trace-context-setter');

/**
 * @classdesc
 * <b>This class is used to enable the distributed tracing 
 * support for variety of Solace message types. 
 * This class is for internal use only.
 * <p>
 * @hideconstructor
 * @internal
 */
class MessageTracingSupport {

  /**
   * Represents OpenTelementry Support for Solace Messages.
   * The solace.Message class will extend this class.
   * 
   * @constructor
   * @hideconstructor
   * @private
   **/
  constructor() {
    // empty constructor
  }

  /**
   * Retrieves a {@link TraceContextSetter} object that is used to modify 
   * an appropriate TraceContext associated with a message transparently.
   * When no context is stored in a message it will create and store a creation 
   * context with a message that can be used as an initial
   * transport context at the same time. 
   * It will never override an existing message creation context. 
   * When creation context is present or only transport context is present, it will
   * override an existing transport context information with a newly provided one.
   * 
   * @returns {solace.TraceContextSetter} Transport context setter object associated with this message; never expected to be null
   */
  getTraceContextSetter() {
    if(this._traceContextSetter == null || this._traceContextSetter == undefined) {
      // create a new context setter object
      this._traceContextSetter = new TraceContextSetter();
    }
    return this._traceContextSetter;
  }

  /**
   * Retrieves a {@link solace.TraceContext} object used for carrying over 
   * of the distributed tracing transport context information usable or modifiable 
   * by intermediary instrumentation across service boundaries. 
   * It allows correlating the producer and the consumer with an intermediary.
   * It also allows correlating multiple intermediaries among each other.
   *
   * @returns {?solace.TraceContext} Transport context object associated with this message
   */
  getTransportContext() {
    return this._transportContext;
  }
  /**
   * The transport context setter
   * 
   * @param {TraceContextSetter} contextSetter 
   */
  _setTransportContext(contextSetter) {
    if(contextSetter != null) {
      this._transportContext = new TraceContext(contextSetter);
    }
  }

  /**
   * Retrieves a {@link solace.TraceContext} object used for carrying over of the distributed tracing
   * message creation context information usable by intermediary instrumentation across
   * service boundaries. It allows correlating the producer with the consumers of a message,
   * regardless of intermediary instrumentation. It must not be altered by intermediaries.
   *
   * @returns {?solace.TraceContext} Message creation context object associated with this message;
   */
  getCreationContext() {
    // if there is no creation context, use the context setter to create it
    return this._creationContext;
  }
  /**
   * It is created only once by the producer/publisher and cannot be
   * changed by intermediaries
   * @param {TraceContextSetter} contextSetter 
   */
  _setCreationContext(contextSetter) {
    // set the creation context only once
    if(contextSetter != null) {
      this._creationContext = new TraceContext(contextSetter);
    }
  }
 
  /**
   * Retrieves a {@link solace.Baggage} carrier object used for carrying over of the distributed tracing
   * message baggage information across service boundaries.  It must not be altered by
   * intermediaries.
   *
   * @return {?solace.Baggage} baggage carrier object associated with this message,
   */
  getBaggage() {
    return this._baggage;
  }
  /**
   * The internal setter for the baggage prop.
   * 
   * @param {Baggage} value 
   */
  _setBaggage(value) {
    this._baggage = value;
  }
}

module.exports.MessageTracingSupport = MessageTracingSupport;
