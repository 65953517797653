const cloneObj = require('./clone'); // using the modified local clone version

const CLONE_CIRCULAR = false; // If true, handles circular references safely.
const CLONE_DEPTH = 1; // Don't deep-clone nested values.

/**
 * @memberof solace
 * @private
 */
class APIProperties {

  /**
   * Base class for all API properties structures. Provides the clone() method to
   * make all properties cloneable.
   * Creates an instance of APIProperties. Assigns properties from one or more
   * objects in increasing priority order.
   *
   * @example
   * super({ foo: 'defaultFoo', bar: 42 }, { foo: 'quux' }); // .foo == quux, .bar = 42
   *
   * @param {...any} options The properties sources to assign.
   *
   * @since 10.0.0
   * @constructor
   */
  constructor(...options) {
    Object.assign(this, ...options);
  }

  /**
   * Can be overridden.  For instance sessionProperties
   * overrides this to suppress printing the password.
   * @returns {String} The string representation of this object
   * @private
   */
  toString() {
    return util_inspect(this);
  }

  clone() {
    return cloneObj(this, CLONE_CIRCULAR, CLONE_DEPTH);
  }
}

module.exports.APIProperties = APIProperties;
