const { clone } = require('solclient-util'); // using the modified local clone version
const SolclientFactoryLib = require('solclient-factory');
const { Codec: SDTCodec } = require('solclient-sdt');
const { Convert } = require('solclient-convert');
const { Destination } = require('solclient-destination');
const { ErrorSubcode, OperationError } = require('solclient-error');
const { LOG_DEBUG, LOG_WARN } = require('solclient-log');
const { MessageCacheStatus } = require('./message-cache-status');
const { MessageDeliveryModeType } = require('./message-delivery-mode-types');
const { MessageDumpFlag } = require('./message-dump-flags');
const { MessageDumpUtil } = require('./message-dump-util');
const { MessageType } = require('./message-types');
const { MessageOutcome } = require('./message-outcomes');
const { MessageUserCosType } = require('./message-user-cos-types');
const { Parameter } = require('solclient-validate');
const { RgmidFactory } = require('./replication-group-message-id');
const { SDTField, SDTFieldType, SDTMapContainer, SDTUnsupportedValueError } = require('solclient-sdt');
const { Baggage, MessageTracingSupport, TraceContext, TraceContextSetter } = require('solclient-message-tracing');

const { ProfileBinding } = SolclientFactoryLib;

const {
  utf8ToUcs2,
  anythingToBuffer,
} = Convert;
const {
  isBoolean,
  isEnumMember,
  isInstanceOf,
  isInstanceOfOrNothing,
  isNumberOrNothing,
  isStringOrNothing,
} = Parameter;

// When a message is cloned for sending,
// * We assume that it may NOT include circular references.
//   These are also unsupported in the encoder.
// * We assume that all properties to be cloned are enumerable.
//   This has significant (~10% AD send rate) performance impact.

const MESSAGE_CLONE_OPTIONS = { circular: false, includeNonEnumerable: false };

/**
 * Function called on construct/reset. Sets the initial values for fields that have them.
 * @param {Message} message The message to initialize
 * @internal
 */
function initMessage(message) {
  message._deliveryMode = MessageDeliveryModeType.DIRECT;
  message._userCos = MessageUserCosType.COS1;
  message._cacheStatus = MessageCacheStatus.LIVE;
  message._spoolerUniqueId = undefined;
  /* message._spoolerMessageId does not to be set to undefined
   * message._spoolerMessageId should only be set when
   * message._spoolerUniqueId is set to a value
   * other than undefined or RgmidFactory.INVALID_SUID
   */
  message._priority = undefined;
  message._deliveryCount = -1; // Makes the accessor throw, never returned to user.

  // for tracing support
  message._traceContextSetter = null;
  message._creationContext = null;
  message._transportContext = null;
  message._baggage = new Baggage();
}

/**
 * Function called on reset only. Clears every field in the message. Call #initMessage
 * to set initial values.
 * @param {Message} message The message to clear
 * @internal
 */
function clearMessage(message) {
  const fields = Object.keys(message);
  fields.forEach(f => delete message[f]);
}

/**
 * Function called to clear extended var-len message properties
 * @param {Message} message The message to update
 * @internal
 */
function clearExtendedVarLenParameters(message) {
  // clear the tracing support params since
  // they use extended var-len encoding
  message._transportContext = null;
}


/**
 * @classdesc
 * <b>This class is not exposed for construction by API users. Users should obtain an instance from
 * {@link solace.SolclientFactory.createMessage}</b>
 * <p>
 * A message is a container that can be used to store and send messages to and from the
 * Solace Message Router.
 *
 * Applications manage the lifecycle of a message; a message is created by calling
 * {@link solace.SolclientFactory.createMessage} and is freed by dereferencing it.
 *
 * API operations that cache or mutate messages always take a copy. A message may
 * be created, mutated by the API user, and sent multiple times.
 *
 * The Message Object provides methods to manipulate the common Solace
 * message header fields that are optionally sent in the binary metadata
 * portion of the Solace message.
 *
 * Applications can also use the structured data API {@link solace.Message#setSdtContainer}
 * to add containers (maps or streams) and their fields to the binary payload or
 * to the User Property map contained within the binary metadata.
 *
 * This does not prevent applications from ignoring these
 * methods and sending payload in the binary payload as an opaque binary field for
 * end-to-end communications
 *
 * @memberof solace
 * @extends solace.MessageTracingSupport
 */
class Message extends MessageTracingSupport {

  /**
   * @constructor
   * @hideconstructor
   **/
  constructor() {
    super(); // call the tracing support constructor
    initMessage(this);
  }

  /**
   * Gets the payload type ({@link solace.MessageType}) of the message. A message has a
   * structured payload if one was attached via {@link solace.Message#setSdtContainer} otherwise
   * if the payload is attached via {@link Message@setBinaryAttachment} then it
   * is unstructured ({@link solace.MessageType#BINARY})
   *
   * @returns {solace.MessageType} The structured payload type.
   * @default {solace.MessageType.BINARY}
   */
  getType() {
    return this._messageType || MessageType.BINARY; // This is OK; BINARY === 0.
  }

  /**
   * Sets the application-provided message ID.
   * @param {?String} value The new value for the application-provided message ID.
   */
  setApplicationMessageId(value) {
    this._applicationMessageId = isStringOrNothing('applicationMessageId', value);
  }

  /**
   * Gets the application-provided message ID.
   * @returns {?String} The application provided message ID.
   */
  getApplicationMessageId() {
    return this._applicationMessageId;
  }

  /**
   * Sets the application message type. This value is used by applications
   * only, and is passed through the API and Solace Message Router untouched.
   * @param {?String} value The application message type.
   */
  setApplicationMessageType(value) {
    this._applicationMessageType = isStringOrNothing('applicationMessageType', value);
  }

  /**
   * Gets the application message type. This value is used by applications
   * only, and is passed through the API and Solace Message Router untouched.
   * @returns {?String} The application message type.
   */
  getApplicationMessageType() {
    return this._applicationMessageType;
  }

  /**
   * Gets the binary attachment part of the message.
   *
   * Backward compatibility note: Using the version10 factory profile or older,
   * the binary attachment is returned as a 'latin1' String:
   * Each character has a code in the range * 0-255
   * representing the value of a single received byte at that position.
   *
   * @returns {Uint8Array|String|null} A TypedArray view of the binary attachment.
   */
  getBinaryAttachment() {
    if (this._binaryAttachment && ProfileBinding.value.byteArrayAsString) {
      return this._binaryAttachment.toString('latin1');
    }
    return this._binaryAttachment;
  }

  /**
   * Sets the binary attachment part of the message.
   *
   * The binary attachment is conceptually an array of bytes.
   * When this method is used, the message payload type is {@link solace.MessageType#BINARY}
   * See {@link solace.Message#getType}.
   *
   * Applications may set the binary attachment to NULL or undefined to
   * remove the binary attachment and create a message with no payload.
   *
   * The following types are accepted:
   *   Buffer (the nodeJS native type or equivalent)
   *   ArrayBuffer,
   *   Any DataView or TypedArray,
   *   'latin1' String for backwards compatibility:
   *     each character has a code in the range 0-255
   *     representing exactly one byte in the attachment.
   *
   * @param {Uint8Array|ArrayBufferLike|DataView|String|null|undefined} value Sets the binary attachment part of the message.
   */
  setBinaryAttachment(value) {
    if (value) {
      this._messageType = MessageType.BINARY;
    }
    this._setBinaryAttachment(anythingToBuffer(value));
  }
  _setBinaryAttachment(value) {
    this._binaryAttachment = value;
  }

  /**
   * Given a Message containing a cached message, return the cache Request Id that
   * the application set in the call to {@link solace.CacheSession#sendCacheRequest}.
   *
   * @returns {?Number} The request ID of the cache request associated with this message.
   */
  getCacheRequestId() {
    return this._cacheRequestId;
  }

  /**
   * @private
   * @param {Number} cacheRequestID The cache request ID associated with this message
   */
  _setCacheRequestID(cacheRequestID) {
    this._cacheRequestId = cacheRequestID;
  }

  /**
   * Gets the correlation ID.  The message Correlation Id
   * is carried in the Solace message headers unmodified by the API and
   * the Solace Message Router. This field may be used for peer-to-peer
   * message synchronization and is commonly used for correlating
   * a request to a reply. See {@link solace.Session#sendRequest}.
   * @returns {?String} The correlation ID associated with the message.
   */
  getCorrelationId() {
    return this._correlationId;
  }

  /**
   * Sets the correlation ID. The message Correlation Id
   * is carried in the Solace message headers unmodified by the API and
   * the Solace Message Router. This field may be used for peer-to-peer
   * message synchronization and is commonly used for correlating
   * a request to a reply. See {@link solace.Session#sendRequest}.
   * @param {String|null|undefined} value The correlation ID to associate with the message.
   */
  setCorrelationId(value) {
    this._correlationId = isStringOrNothing('correlationId', value);
  }

  /**
   * Gets the correlation Key. A correlation key is used to correlate
   * a message with its acknowledgement or rejection. The correlation key is an object that is
   * passed back to the client during the router acknowledgement or rejection.
   *
   * The correlation key is a local reference
   * used by applications generating Guaranteed messages. Messages that are
   * sent in either {@link solace.MessageDeliveryModeType.PERSISTENT} or
   * {@link solace.MessageDeliveryModeType.NON_PERSISTENT} mode may set the correlation key.
   * @returns {?Object} The correlation Key associated with the message,
   * or <code>null</code>, if unset.
   */
  getCorrelationKey() {
    return this._correlationKey || null;
  }

  /**
   * Sets the correlation Key. A correlation key is used to correlate
   * a message with its acknowledgement or rejection. The correlation key is an object that is
   * passed back to the client during the router acknowledgement or rejection.
   *
   * The correlation key is a local reference
   * used by applications generating Guaranteed Messages. Messages that are
   * sent in either {@link solace.MessageDeliveryModeType.PERSISTENT} or
   * {@link solace.MessageDeliveryModeType.NON_PERSISTENT} mode may set the correlation key. If this
   * method is used, the correlation information is returned
   * when the {@link solace.SessionEventCode#event:ACKNOWLEDGED_MESSAGE} event
   * is later received for an acknowledged message or when the
   * {@link solace.SessionEventCode#event:REJECTED_MESSAGE_ERROR} is received for a rejected
   * message.
   *
   * The API only maintains a reference to the passed object.  If the application requires the
   * contents are unmodified for proper correlation, then it is the application's responsibility
   * to ensure the contents of the object are not modified.
   *
   * Important: <b>The Correlation Key is not included in the
   * transmitted message and is only used with the local API</b>
   * @param {Object|String|null|undefined} value The correlation Key to associate with the message.
   */
  setCorrelationKey(value) {
    this._correlationKey = value;
  }

  /**
   * Gets whether the message is configured for delivering to one client only.
   * @returns {Boolean} indicates whether the message is configured for
   * delivering to one client only.
   * @deprecated use Shared Subscriptions instead.
   */
  isDeliverToOne() {
    return this._deliverToOne || false;
  }

  /**
   * Sets whether the message is configured for delivering to one client only.
   * @param {Boolean} value whether the message is configured for delivering to one client only.
   * @deprecated use Shared Subscriptions instead.
   */
  setDeliverToOne(value) {
    this._setDeliverToOne(this._deliverToOne = isBoolean('deliverToOne', value));
  }
  _setDeliverToOne(value) {
    this._deliverToOne = value;
  }

  /**
   * Gets the delivery mode of the message.
   * @returns {solace.MessageDeliveryModeType} representing the delivery mode of the message.
   */
  getDeliveryMode() {
    return this._deliveryMode;
  }

  /**
   * Sets the delivery mode of the message.
   * @param {solace.MessageDeliveryModeType} value The message delivery mode.
   */
  setDeliveryMode(value) {
    this._setDeliveryMode(isEnumMember('deliveryMode', value, MessageDeliveryModeType));
  }
  _setDeliveryMode(value) {
    this._deliveryMode = value;
  }

  /**
   * Gets the destination to which the message was published.
   * @returns {?Destination} The destination to which a message was published.
   */
  getDestination() {
    return this._destination;
  }

  /**
   * Sets the destination ({@link solace.DestinationType#Topic} or
   * {@link solace.DestinationType#Queue}) to publish the message to.
   * @param {Destination} value The destination to publish the message to.
   */
  setDestination(value) {
    this._setDestination(isInstanceOf('destination', value, Destination));
  }
  _setDestination(value) {
    this._destination = value;
  }

  /**
   * Indicates whether one or more messages have been discarded prior
   * to the current message. This indicates congestion discards only and
   * is not affected by message eliding.
   * @returns {Boolean} Returns true if one or more messages have been
   * discarded prior to the current message; otherwise, it returns false.
   */
  isDiscardIndication() {
    return this._discardIndication || false;
  }

  /**
   * @private
   * @param {Boolean} value The new value for discard indication
   */
  setDiscardIndication(value) {
    this._setDiscardIndication(isBoolean('discardIndication', value));
  }
  _setDiscardIndication(value) {
    this._discardIndication = value;
  }

  /**
   * Returns whether the message is eligible for eliding.
   * <p>
   * Message eliding enables filtering of data to avoid transmitting
   * every single update to a subscribing client.
   * <p>
   * This property does not indicate whether the message was elided.
   *
   * @returns {Boolean} indicates whether the message is eligible for eliding.
   */
  isElidingEligible() {
    return this._elidingEligible || false;
  }

  /**
   * Sets whether the message is eligible for eliding.
   * <p>
   * Message eliding enables filtering of data to avoid transmitting
   * every single update to a subscribing client.
   * <p>
   * This property does not indicate whether the message was elided.
   *
   * @param {Boolean} value sets whether the message is eligible for eliding.
   */
  setElidingEligible(value) {
    this._setElidingEligible(isBoolean('setElidingEligible', value));
  }
  _setElidingEligible(value) {
    this._elidingEligible = value;
  }

  /// ---------------------------------------------------------
  // * Internal use only methods on the message, for fields set by the internal
  // * publisher
  /// ---------------------------------------------------------

  /**
   * @returns {Number} The publisher ID
   * @private
   */
  getPublisherId() {
    return this._publisherId;
  }
  /**
   * @param {Number} value  The publisher ID to set
   * @private
   */
  setPublisherId(value) {
    this._publisherId = value;
  }

  /**
   * @returns {Number} The publisher message ID
   * @private
   */
  getPublisherMessageId() {
    return this._publisherMsgId;
  }
  /**
   * @param {Number} value The publisher message ID to set
   * @private
   */
  setPublisherMessageId(value) {
    this._publisherMsgId = value;
  }

  /// -------------------------------------------------------------
  // * User-settable properties for publishing
  /// -------------------------------------------------------------

  /**
   * @returns {Number} The Guaranteed Message TTL, in milliseconds.
   */
  getTimeToLive() {
    return this._timeToLive;
  }
  /**
   * @param {Number} value The Guaranteed Message TTL to set, in milliseconds.
   *
   * The time to live is the number of milliseconds the message may be stored on the
   * Solace Message Router before the message is discarded or moved to a Dead Message
   * Queue. See {@link solace.Message.setDMQEligible}.
   *
   * Setting the Time To Live to zero disables TTL for the message.
   *
   * This property is only valid for Guaranteed messages (Persistent and Non-Persistent).
   * It has no effect when used in conjunction with other message types unless the message
   * is promoted by the appliance to a Guaranteed message.
   *
   * The maxium allowed time to live is 3.1536E11 (315360000000) which is
   * approximately 10 years.
   */
  setTimeToLive(value) {
    const MAX_MESSAGE_TTL_MS = (10 * 365 * 24 * 60 * 60 * 1000); // approximately 10 years

    // Allow parser and initializer to set timeToLive to undefined
    if (value === null || value === undefined) {
      this._timeToLive = value;
      return;
    }
    if (typeof value !== 'number' || isNaN(value)) {
      throw new OperationError('Invalid type for time to live',
        ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
    if (value < 0 || value > MAX_MESSAGE_TTL_MS) {
      throw new OperationError('Invalid time to live value',
        ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
    this._timeToLive = value;
  }

  /**
   * @returns {Number|undefined} The Guaranteed Message expiration value.
   * The expiration time is the UTC time
   * (that is, the number of milliseconds from midnight January 1, 1970 UTC) when the
   * message is to expire.
   */
  getGMExpiration() {
    return this._expiration;
  }
  /**
   * Set the expiration time field. The expiration time is the UTC time
   * (that is, the number of milliseconds from midnight January 1, 1970 UTC) when the
   * message is to expire. The expiration time is carried in the message when set to
   * a non-zero value. Expiration time is not included when this value is set to zero or
   * undefined
   *
   * The message expiration time is carried to clients that receive the message
   * unmodified and does not effect the life cycle of the message. Use
   * {@link solace.Message#setTimeToLive} to enforce message expiry in the network.
   *
   * @param {?Number} value The new Guaranteed Message expiration value
   */
  setGMExpiration(value) {
    this._expiration = isNumberOrNothing('GMExpiration', value);
  }

  /**
   * @returns {Boolean} Whether this message is Guaranteed Message DMQ eligible
   */
  isDMQEligible() {
    return this._dmqEligible || false;
  }
  /**
   * @param {Boolean} value The new value for Guaranteed Message DMQ (Dead Message Queue) Eligible.
   * When this property is set, when the message expires in the network
   * the message is saved on a appliance dead message queue. Otherwise the expired message is
   * discarded. See {@link solace.Message#setTimeToLive}.
   * @default false
   */
  setDMQEligible(value) {
    this._setDMQEligible(isBoolean('DMQEligible', value));
  }
  _setDMQEligible(value) {
    this._dmqEligible = value;
  }

  /// ---------------------------------------------------------
  // * Internal use only methods on the message, for fields set by the consumer flow
  /// ---------------------------------------------------------

  /**
   * @returns {Long} The ID of the flow that received this message
   * @private
   */
  getFlowId() {
    return this._flowId;
  }
  /**
   * @param {Long} value The flow ID that received this message
   * @private
   */
  setFlowId(value) {
    this._flowId = value;
  }

  /**
   * @returns {Long} The Guaranteed Message prevMsgId
   * @private
   */
  getGuaranteedPreviousMessageId() {
    return this._guaranteedPrevMsgId;
  }
  /**
   * @param {Long} value The Guaranteed Message prevMsgId to set
   * @private
   */
  setGuaranteedPreviousMessageId(value) {
    this._guaranteedPrevMsgId = value;
  }

  /**
   * @param {Long} value Spooler unique id value
   * @private
   */
  _setSpoolerUniqueId(value) {
    this._spoolerUniqueId = value;
  }

  /**
   * @returns {?Long} value of Spooler unique id
   * @private
   */
  _getSpoolerUniqueId() {
    // This can be set via the smf header or by the message consumer
    // using _setSpoolerUniqueId.
    // The message consumer check if a data message has a suid set
    // the updates the consumer stored value for suid from the message value.
    // If the message does not have a suid the message consumer sets the suid
    // of the message using _setSpoolerUniqueId.
    // See consumer-fsm.acceptMessage for details.
    if (this._spoolerUniqueId === undefined) {
      return RgmidFactory.INVALID_SUID;
    }
    return this._spoolerUniqueId;
  }

  /// ---------------------------------------------------------
  // * Properties set by the Message Consumer
  /// ---------------------------------------------------------

  /**
   * @returns {?solace.MessageConsumer} The associated Message Consumer, if received by a consumer
   */
  getMessageConsumer() {
    return this._consumer;
  }
  /**
   * @param {solace.MessageConsumer} value The Message Consumer to associate with this message
   * @private
   */
  setMessageConsumer(value) {
    this._consumer = value;
  }

  /**
   * This message ID is NOT a universal unique identifier for the message.
   * There is no use for this message ID in an application.
   * Internally this message ID is used when a message is acknowledged
   * calling Message.acknowledge().
   * No other meaning should be inferred from the value of this message ID.
   * @returns {?Long} A field in the message structure intended for internal use.
   * @deprecated There is no useful purpose for this method. Do not use it.
   */
  getGuaranteedMessageId() {
    return this._guaranteedMsgId;
  }
  /**
   * @param {Long} value The Guaranteed Message msgId to set
   * @private
   */
  setGuaranteedMessageId(value) {
    this._guaranteedMsgId = value;
  }

  /**
   * @param {Long} value Spooler message id value, this can be the mateAckId or the AckId
   * @private
   */
  _setSpoolerMessageId(value) {
    this._spoolerMessageId = value;
  }

  /**
   * Returns the Replication Group Message Id
   * @returns {?solace.ReplicationGroupMessageId} The replication group message id
   *  assigned by the router.
   */
  getReplicationGroupMessageId() {
    if (this._spoolerUniqueId === undefined
       || RgmidFactory.INVALID_SUID.eq(this._spoolerUniqueId)) {
      return undefined;
    }
    /* use this._spoolerMessageId if possible otherwise use this._guaranteedMsgId */
    const smid = this._spoolerMessageId || this._guaranteedMsgId;
    /* Note smid as this._guaranteedMsgId must have a value
     * if this._spoolerUniqueId is not RgmidFactory.INVALID_SUID */
    return RgmidFactory.from({ suid: this._spoolerUniqueId, msgid: smid });
  }

  /**
   * Returns the Topic Sequence Number.  If there is no topic sequence number
   * undefined is returned.
   * @returns {?Long} The Topic Sequence number assigned to this message by the Message Router.
   */
  getTopicSequenceNumber() {
    return this._topicSequenceNumber;
  }

  /**
   * @param {Long} topicSeqNo The Topic Sequence Number to set
   * @private
   */
  setTopicSequenceNumber(topicSeqNo) {
    this._topicSequenceNumber = topicSeqNo;
  }

  /**
   * Returns the delivery count.
   *
   * @returns {Number|undefined} The delivery count reported by the broker.
   * @throws {solace.OperationError} if endpoint does not report delivery count (message is not persistent).
   */

  getDeliveryCount() {
    if (this._deliveryCount === -1) {
      throw new OperationError('Endpoint does not report delivery count.',
        ErrorSubcode.INVALID_OPERATION);
    } // else
    return this._deliveryCount;
  }

  /**
   * @param {Number} deliveryCount on this message.
   * @private
   */
  setDeliveryCount(deliveryCount) {
    this._deliveryCount = deliveryCount;
  }


  /**
   * Settles this message in a requested way.
   *
   * If the {@link solace.MessageConsumer} on which this message was received is configured to use
   * {@link solace.MessageConsumerAckMode.CLIENT}, then when a message is received by an
   * application, the application must call this method to explicitly acknowledge reception of the
   * message. This frees local and router resources associated with an unacknowledged message.
   *
   * The API does not send acknowledgments immediately. It stores the state for
   * acknowledged messages internally and acknowledges messages, in bulk, when a
   * threshold or timer is reached.
   *
   * @param {solace.MessageOutcome} messageSettlementOutcome type of the settlement outcome, not expected to be {@code null}
   * @throws {@link solace.OperationError}
   *  * if broker does not support the specified outcome option;
   *  * if the flow was created without specifying required {@link MessageOutcome}
   *    Options within the consumer properties;
   *  * if the associated {@link solace.Session} is configured
   *    to use SUPPORTED_MESSAGE_ACK_AUTO (the default behaviour),
   *    the use of {@link MessageOutcome#REJECTED}
   *    and {@link MessageOutcome#FAILED} are not supported;
   *  * if the associated {@link solace.Session} is not connected;
   *    subcode: {@link solace.ErrorSubcode.SESSION_NOT_CONNECTED}
   *  * if the associated {@link solace.MessageConsumer} is not connected
   *    subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}
   *
   *    @see {@link solace.MessageOutcome}for detailed explanation of available options
   */
  settle(messageSettlementOutcome) {
    // implementation here
    // validation checks here
    if (this._acked) {
      throw new OperationError('Message can only be settled once',
        ErrorSubcode.MESSAGE_ALREADY_ACKNOWLEDGED);
    }
    if (this._deliveryMode === MessageDeliveryModeType.DIRECT) {
      throw new OperationError('Cannot settle a DIRECT message',
        ErrorSubcode.MESSAGE_DELIVERY_MODE_MISMATCH);
    }
    if (!this._consumer) {
      throw new OperationError('Cannot settle a locally-created message',
        ErrorSubcode.MESSAGE_DELIVERY_MODE_MISMATCH);
    }
    if (!this._consumer._sessionInterface.canAck) {
      throw new OperationError('Cannot settle using associated session',
        ErrorSubcode.SESSION_NOT_CONNECTED);
    }
    if (!this._consumer.canAck) {
      throw new OperationError('Cannot settle using associated Message Consumer',
        ErrorSubcode.INVALID_OPERATION);
    }
    if (this._consumer.getProperties().browser) {
      throw new OperationError('Messages delivered to a Queue Browser can only be deleted by calling QueueBrowser.removeMessageFromQueue()',
        ErrorSubcode.INVALID_OPERATION);
    }
    // check to ensure that you cannot manually settle a message with acknowledgeMode = AUTO
    if (this._consumer._fsm.hasAutoAckSupport) {
      LOG_WARN(`Consumer configured to auto-acknowledge messages, so message ${
        this._guaranteedMsgId} cannot be application settled`);
      return;
    }

    // validate the settlement outcome and send correct Enum value
    const settlementOutcome = messageSettlementOutcome;
    if (MessageOutcome.values.indexOf(settlementOutcome) === -1) {
      throw new OperationError(`Settlement outcome for message must be valid`,
        ErrorSubcode.INVALID_OPERATION);
    }

    // check that the broker consumer session has support for the outcome and 
    const doesConsumerSupportOutcome = this._consumer.getProperties().requiredSettlementOutcomes.some(v => v === settlementOutcome);
    // that the session was created with support for the outcome
    if (settlementOutcome != MessageOutcome.ACCEPTED && !doesConsumerSupportOutcome) {
      throw new OperationError(`solace.MessageOutcome.${MessageOutcome.nameOf(settlementOutcome)} not supported for this Message Consumer`,
        ErrorSubcode.INVALID_OPERATION);
    }

    // actual settlement logic
    this._consumer.applicationSettle(this._guaranteedMsgId, settlementOutcome);
    this._acked = true; // also set as ACKed/settled
  }

  /**
   * Returns whether settle(solace.MessageOutcome) has been called on this message.
   *
   * @readonly
   * @type {Boolean}
   */
  get isSettled() {
    return this._acked || false;
  }

  /**
   * Internal validation checks before we attempt to acknowledge this message.
   * @private
   */
  _validateBeforeAcknowledge() {
    if (this._acked) {
      throw new OperationError('Message can only be acknowledged once',
        ErrorSubcode.MESSAGE_ALREADY_ACKNOWLEDGED);
    }
    if (this._deliveryMode === MessageDeliveryModeType.DIRECT) {
      throw new OperationError('Cannot acknowledge a DIRECT message',
        ErrorSubcode.MESSAGE_DELIVERY_MODE_MISMATCH);
    }
    if (!this._consumer) {
      throw new OperationError('Cannot acknowledge a locally-created message',
        ErrorSubcode.MESSAGE_DELIVERY_MODE_MISMATCH);
    }
    if (!this._consumer._sessionInterface.canAck) {
      throw new OperationError('Cannot acknowledge using associated session',
        ErrorSubcode.SESSION_NOT_CONNECTED);
    }
    if (!this._consumer.canAck) {
      throw new OperationError('Cannot acknowledge using associated Message Consumer',
        ErrorSubcode.INVALID_OPERATION);
    }
    if (this._consumer.getProperties().browser) {
      throw new OperationError('Messages delivered to a Queue Browser can only be deleted by calling QueueBrowser.removeMessageFromQueue()',
        ErrorSubcode.INVALID_OPERATION);
    }
  }

  /**
   * Acknowledges this message.
   * This is the same as calling {@link solace.Message#settle(solace.MessageOutcome.ACCEPTED)}.
   * Internally uses Message.settle() with the ACCEPTED MessageOutcome.
   *
   * If the {@link solace.MessageConsumer} on which this message was received is configured to use
   * {@link solace.MessageConsumerAckMode.CLIENT}, then when a message is received by an
   * application, the application must call this method to explicitly acknowledge reception of the
   * message. This frees local and router resources associated with an unacknowledged message.
   *
   * The API does not send acknowledgments immediately. It stores the state for
   * acknowledged messages internally and acknowledges messages, in bulk, when a
   * threshold or timer is reached.
   *
   * @throws {@link solace.OperationError}
   *  * if this message was not received via Guaranteed Message;
   *    subcode: {@link solace.ErrorSubcode.MESSAGE_DELIVERY_MODE_MISMATCH}
   *  * if the associated {@link solace.Session} is not connected;
   *    subcode: {@link solace.ErrorSubcode.SESSION_NOT_CONNECTED}
   *  * if the associated {@link solace.MessageConsumer} is not connectedl
   *    subcode: {@link solace.ErrorSubcode.INVALID_OPERATION}
   */
  acknowledge() {
    // call all the validations before we acknowledge message
    this._validateBeforeAcknowledge();

    // check to ensure that you cannot manually settle a message with acknowledgeMode = AUTO
    if (this._consumer._fsm.hasAutoAckSupport) {
      LOG_WARN(`Consumer configured to auto-acknowledge messages, so message ${
        this._guaranteedMsgId} cannot be application acknowledge`);
      return;
    }

    // use settlement implementation with ACCEPTED outcome
    this._consumer.applicationAck(this._guaranteedMsgId, false);
    this._acked = true; // also set message as acked/settled
  }

  /**
   * Used by the message-dispatcher to auto-acknowledge this message.
   * @internal
   */
  _autoAcknowledge() {
    // call all the validations before we acknowledge message
    this._validateBeforeAcknowledge();

    // use settlement implementation with ACCEPTED outcome
    this._consumer.applicationAck(this._guaranteedMsgId, true);
    this._acked = true; // also set message as acked/settled
  }

  /**
   * Returns whether acknowledge() has been called on this message.
   *
   * @readonly
   * @type {Boolean}
   */
  get isAcknowledged() {
    return this._acked || false;
  }

  /**
   * Test if the Acknowledge Immediately message property is set or not.
   * When the Acknowledge Immediately property is set to true on an outgoing
   * Guaranteed Message,
   * it indicates that the Solace Message Router should Acknowledge this message
   * immediately upon receipt.
   *
   * This property, when set by a publisher, may or may not be removed by the
   * Solace Message Router prior to delivery to a consumer, so message consumers
   * must not expect the property value indicates how the message was
   * originally published
   * @returns {Boolean} Whether this message was set to acknowledge immediately.
   */
  isAcknowledgeImmediately() {
    return this._ackImmediately || false;
  }
  /**
   * Set the optional Acknoweledge Immediately message property.
   * When the Acknowledge Immediately property is set to true on an outgoing Guaranteed Message,
   * it indicates that the Solace Message Router should acknoweledge this message
   * immediately upon receipt. By default the property is set to false on newly created messages.
   *
   * This property, when set by a publisher, may or may not be removed by the appliance
   * prior to delivery to a consumer, so message consumers must not expect the property value
   * indicates how the message was originally published. Therefore if a received message
   * is forwarded by the application, the Acknowledge Immediately property should be
   * explicitly set to the desired value (true or false).
   *
   * Setting this property on an outgoing direct message has no effect.
   *
   * @param {Boolean} value Whether to acknowledge this message immediately.
   */
  setAcknowledgeImmediately(value) {
    this._setAcknowledgeImmediately(isBoolean('acknowledgeImmediately', value));
  }
  _setAcknowledgeImmediately(value) {
    this._ackImmediately = value;
  }

  /**
   * Gets the cache status of this message.
   *
   * @returns {?solace.MessageCacheStatus} The cache status of this message. The status
   * will be MessageCacheStatus.LIVE unless the message was returned in a
   * reply to a cache request.
   */
  getCacheStatus() {
    return this._cacheStatus;
  }

  /**
   * @param {solace.MessageCacheStatus} cacheStatus The new cache status for this message
   * @private
   */
  _setCacheStatus(cacheStatus) {
    this._cacheStatus = cacheStatus;
  }

  /**
   * Returns whether the message's reply field is set, indicating
   * that this message is a reply to a previous request. See {@link solace.Session#sendRequest}.
   * @returns {Boolean} Indicates the state of the reply field.
   */
  isReplyMessage() {
    return this._replyMessage || false;
  }

  /**
   * Indicates whether the message has been marked as redelivered by the Solace Message Router.
   * @returns {Boolean} Indicates whether the redelivered flag is set.
   */
  isRedelivered() {
    return this._redelivered || false;
  }
  /**
   * @param {Boolean} value The redelivered flag
   * @private
   */
  setRedelivered(value) {
    this._redelivered = value;
  }

  /**
   * Sets the <i>reply</i> field of the message.
   * @param {Boolean} value Sets whether to flag the message as a reply.
   */
  setAsReplyMessage(value) {
    this._replyMessage = isBoolean('asReplyMessage', value);
  }

  /**
   * Gets the receive timestamp (in milliseconds, from midnight, January 1, 1970 UTC).
   * @returns {?Number} The receive timestamp, if set.
   */
  getReceiverTimestamp() {
    return this._receiverTimestamp;
  }

  /**
   * Gets the replyTo destination
   * @returns {?solace.Destination} The value of the replyTo destination, if set.
   */
  getReplyTo() {
    return this._replyTo;
  }

  /**
   * Sets the replyTo destination
   * @param {solace.Destination} value The replyTo destination.
   */
  setReplyTo(value) {
    this._replyTo = isInstanceOfOrNothing('replyTo', value, Destination);
  }

  /**
   * Returns the Sender's ID.
   * @returns {?String} The Sender's ID, if set.
   */
  getSenderId() {
    return this._senderId;
  }

  /**
   * Sets the Sender ID for the message
   * @param {String} value The Sender ID for the message.
   */
  setSenderId(value) {
    this._senderId = isStringOrNothing('senderId', value);
  }

  /**
   * Gets the send timestamp (in milliseconds, from midnight, January 1,
   * 1970 UTC).
   * @returns {?Number} The send timestamp, if set.
   */
  getSenderTimestamp() {
    return this._senderTimestamp;
  }

  /**
   * Sets the send timestamp (in milliseconds, from midnight, January 1,
   * 1970 UTC). This field can be generated automatically during message
   * publishing, but it will not be generated if previously set to a non-null value by this method.
   * See {@link solace.SessionProperties#generateSendTimestamps}.
   *
   * An application that publishes the same {@link solace.Messsage} multiple times and
   * also wants generted timestamps on each messages, should set the sender timestamp
   * to undefined after each call to {@link solace.Session#send}.
   * @param {?Number} value The value to set as the send timestamp.
   */
  setSenderTimestamp(value) {
    this._senderTimestamp = isNumberOrNothing('senderTimestamp', value);
  }

  /**
   * Gets the sequence number.
   * <p>
   * This is an application-defined field,
   * see <code>{@link solace.Message#setSequenceNumber}()</code>.
   * @returns {?Number} The sequence number, if set
   * @throws {@link solace.SDTUnsupportedValueError} in case the sequence number is out of range.
   */
  getSequenceNumber() {
    if (this._sequenceNumberError) {
      throw this._sequenceNumberError;
    }
    return this._sequenceNumber;
  }

  /**
   * Sets the application-defined sequence number. If the sequence number
   * is not set, or set to undefined, and {@link solace.SessionProperties#generateSequenceNumber}
   * is true, then a sequence number is automatically generated for each sent message.
   * @param {?Number} value The sequence number.
   */
  setSequenceNumber(value) {
    if (value instanceof SDTUnsupportedValueError) {
      this._sequenceNumberError = value;
    } else {
      this._sequenceNumber = isNumberOrNothing('sequenceNumber', value);
      this._sequenceNumberError = undefined;
      //TODO: this is probably wrong. Shouldn't it be cleared if value === undefined?
      this._autoSequenceNumber = false;
    }
  }

  /**
   * Gets the Class of Service (CoS) value for the message.
   * The Class of Service has different semantics for direct and guaranteed messages.
   *
   * For messages published with {@link solace.MessageDeliveryModeType.DIRECT}, the
   * class of service selects the weighted round-robin delivery queue when the
   * message is forwarded to a consumer.  {@link solace.MessageUserCosType.COS1} are the
   * lowest priority messages and will use the Solace Message Router D-1 delivery queues.
   *
   * For messages published as guaranteed messages
   * ({@link solace.MessageDeliveryModeType.PERSISTENT} or
   * {@link solace.MessageDeliveryModeType.NON_PERSISTENT}), messages published
   * with {@link solace.MessageUserCosType.COS1} can be rejected by the Solace Message Router if
   * that message would cause any queue or topic-endpoint to exceed its configured
   * low-priority-max-msg-count.
   *
   * @returns {solace.MessageUserCosType} The COS value.
   */
  getUserCos() {
    return this._userCos;
  }

  /**
   * Gets the Message Priority Parameter (JMS Priority) value for the message.
   * Numerical values between 0 and 255 are valid return values,
   * undefined means the parameter is not present.
   *
   * If destination queues and topic endpoints for this message
   * are configured to respect message priority,
   * the values 0 through 9 can be used to affect the priority
   * of delivery to consumers of those queues or topic endpoints.
   * For the purposes of prioritized message delivery,
   * values larger than 9 are treated the same as 9.
   *
   * @returns {Number} The Message Priority Parameter value.
   */
  getPriority() {
    return this._priority;
  }

  /**
   * Sets the Class of Service (CoS) value for the message.
   *
   * The Class of Service has different semantics for direct and guaranteed messages.
   *
   * For messages published with {@link solace.MessageDeliveryModeType.DIRECT}, the
   * class of service selects the weighted round-robin delivery queue when the
   * message is forwarded to a consumer.  {@link solace.MessageUserCosType#COS1} are the
   * lowest priority messages and will use the Solace Message Router D-1 delivery queues.
   *
   * For messages published as guaranteed messages
   * ({@link solace.MessageDeliveryModeType.PERSISTENT} or
   * {@link solace.MessageDeliveryModeType.NON_PERSISTENT}), messages published
   * with {@link solace.MessageUserCosType#COS1} can be rejected by the Solace Message Router if
   * that message would cause any queue or topic-endpoint to exceed its configured
   * low-priority-max-msg-count.
   *
   * @param {solace.MessageUserCosType} value The COS value.
   * @default {solace.MessageUserCosType#COS1}
   */
  setUserCos(value) {
    this._setUserCos(isEnumMember('userCos', value, MessageUserCosType));
  }
  _setUserCos(value) {
    this._userCos = value;
  }

  /**
   * Sets the Message Priority Parameter (JMS Priority) value for the message.
   * Numerical values between 0 and 255 are accepted,
   * use undefined to unset.
   *
   * If destination queues and topic endpoints for this message
   * are configured to respect message priority,
   * the values 0 through 9 can be used to affect the priority
   * of delivery to consumers of those queues or topic endpoints.
   * For the purposes of prioritized message delivery, values larger than 9
   * are treated the same as 9.
   *
   * @param {?Number} value The priority value.
   */
  setPriority(value) {
    if (value === undefined || value === null) {
      this._setPriority(undefined);
      return;
    }
    if (typeof value !== 'number' || isNaN(value)) {
      throw new OperationError('Invalid type for message priority',
        ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
    if (value < 0 || value > 255) {
      throw new OperationError('Invalid priority value',
        ErrorSubcode.PARAMETER_OUT_OF_RANGE);
    }
    this._setPriority(value);
  }

  _setPriority(value) {
    this._priority = value;
  }

  /**
   * Gets the user data part of the message.
   * @returns {String} The user data part of the message, if set.
   */
  getUserData() {
    return this._userData;
  }

  /**
   * Sets the user data part of the message.
   * @param {String} value The user data part of the message.
   */
  setUserData(value) {
    this._setUserData(isStringOrNothing('userData', value));
  }
  _setUserData(value) {
    this._userData = value;
  }

  /**
   * Gets the XML content part of the message.
   * Notice that the content is encoded as UTF-8 characters,
   * it needs to be decoded as JavaScript surrogate pair: decodeURIComponent(escape(value))
   * @returns {?String} The XML content part of the message, if set.
   */
  getXmlContent() {
    return this._xmlContent;
  }

  /**
   * Gets the XML content part of the message decoded from UTF-8 encoding of the characters.
   * @returns {?String} The XML content part of the message. Returns <code>null</code> if not
   * present.
   */
  getXmlContentDecoded() {
    return this._xmlContent ? utf8ToUcs2(this._xmlContent) : this._xmlContent;
  }

  /**
   * Sets the XML content part of the message.
   * The content is encoded by replacing each instance of certain characters
   * by one, two, three, or four escape sequences representing the
   * UTF-8 encoding of the character.
   * @param {String} value The XML content part of the message.
   */
  setXmlContent(value) {
    const setValue = isStringOrNothing('xmlContent', value);
    this._xmlContent = setValue ? unescape(encodeURIComponent(setValue)) : setValue;
  }

  /**
   * Internal set for the XML content part of the message.
   * @param {String} value The XML content part of the message.
   * @private
   */
  _setXmlContentInternal(value) {
    this._xmlContent = isStringOrNothing('xmlContentInternal', value);
  }

  /**
   * Sets the message's XML metadata section.
   * @param {String} value The XML metadata.
   */
  setXmlMetadata(value) {
    this._setXmlMetadata(isStringOrNothing('xmlMetadata', value));
  }
  _setXmlMetadata(value) {
    this._xmlMetadata = value;
  }

  /**
   * Gets the message's XML metadata section.
   * @returns {?String} The XML metadata, if set.
   */
  getXmlMetadata() {
    return this._xmlMetadata;
  }

  /**
   * @private
   */
  get binaryMetadataChunk() {
    return this._binaryMetaChunk || null;
  }
  /**
   * @param {?String} meta The meta chunk to set
   * @private
   */
  set binaryMetadataChunk(meta) {
    this._binaryMetaChunk = meta;
  }

  /**
   * @private
   */
  get smfHeader() {
    return this._smfHeader;
  }
  /**
   * @param {SMFHeader} val The SMF header to set
   * @private
   */
  set smfHeader(val) {
    this._smfHeader = val;
  }

  /**
   * @private
   */
  get hasAutoSequenceNumber() {
    return this._autoSequenceNumber || false;
  }
  /**
   * @param {Boolean} value Value to set
   * @private
   */
  set hasAutoSequenceNumber(value) {
    this._autoSequenceNumber = value;
  }

  /**
   * @private
   */
  get hasAutoSenderTimestamp() {
    return this._autoSenderTimestamp || false;
  }
  /**
   * @param {Number} value Value to set
   * @private
   */
  set hasAutoSenderTimestamp(value) {
    this._autoSenderTimestamp = value;
  }

  /**
   * Gets the user property map carried in the message binary metadata.
   *
   * @returns {?solace.SDTMapContainer} The user properties map, if set.
   */
  getUserPropertyMap() {
    return this._userPropertyMap;
  }

  /**
   * Allows users to specify their own user properties to be carried
   * in the message binary metadata separate from the payload.
   * @param {?SDTMapContainer} value The user property map.
   */
  setUserPropertyMap(value) {
    this._userPropertyMap = isInstanceOfOrNothing('userPropertyMap', value, SDTMapContainer);
  }

  /**
   * Makes this message a strutured data message by assigning it a
   * structured data type (SDT) container payload (such as a
   * {@link solace.SDTMapContainer}, {@link solace.SDTStreamContainer}
   * or a {@link solace.SDTFieldType.String}, which is transported in the binary attachment field.
   *
   * Assigning a SDT container updates the message's Type property to
   * the appropriate value.
   *
   * The container argument must be a {@link solace.SDTField} with a type
   * of {@link solace.SDTFieldType.MAP}, {@link solace.SDTFieldType.STREAM},
   * or {@link solace.SDTFieldType.STRING}.
   *
   * @param {?solace.SDTField} container The SDTField container to send in this message.
   */
  setSdtContainer(container) {
    const structuredContainer = isInstanceOfOrNothing('sdtContainer', container, SDTField);
    if (structuredContainer === null || structuredContainer === undefined) {
      // clear
      this._structuredContainer = null;
      this.setBinaryAttachment(null);
      return;
    }

    this._setBinaryAttachment(null);
    const sdtType = structuredContainer.getType();
    switch (sdtType) {
      case SDTFieldType.MAP:
        this._messageType = MessageType.MAP;
        break;
      case SDTFieldType.STREAM:
        this._messageType = MessageType.STREAM;
        break;
      case SDTFieldType.STRING:
        this._messageType = MessageType.TEXT;
        break;
      default:
        throw new OperationError('Invalid parameter: expected SDTField Type of ' +
          'MAP, STREAM, or STRING.',
          ErrorSubcode.PARAMETER_INVALID_TYPE);
    }
    this._structuredContainer = structuredContainer;
  }

  /**
   * Gets the message's structured data container, if this is a structured data message.
   *
   * @returns {SDTField|null} A field with a payload of {String}, {@link SDTMapContainer},
   * or {@link SDTStreamContainer} if one was set in the message; otherwise, null.
   */
  getSdtContainer() {
    const msgType = this.getType();
    const binaryAttachment = this._binaryAttachment;
    const binaryAttachmentLength = binaryAttachment ? binaryAttachment.length : 0;

    if (msgType === MessageType.BINARY) {
      LOG_DEBUG(`getSdtContainer returned null, reason: msgType=${msgType}`);
      return null;
    }

    // MAP, STREAM or TEXT
    // Use cached structured container if available
    if (typeof this._structuredContainer !== 'undefined') {
      return this._structuredContainer;
    }

    if (binaryAttachmentLength === 0) {
      LOG_DEBUG(`getSdtContainer returned null, reason: len=${binaryAttachmentLength}`);
      this._structuredContainer = null;
    } else {
      // Last resort: Decode binary attachment.
      // Cache structured container for later access
      this._structuredContainer = SDTCodec.parseSingleElement(binaryAttachment, 0);
    }


    return this._structuredContainer;
  }

  // Message Tracing Section

  /**
   * Gets the sequence number.
   * <p>
   * This is an application-defined field,
   * see <code>{@link solace.Message#setSequenceNumber}()</code>.
   * @returns {?Number} The sequence number, if set
   * @throws {@link solace.SDTUnsupportedValueError} in case the sequence number is out of range.
   */
  getSequenceNumber() {
    if (this._sequenceNumberError) {
      throw this._sequenceNumberError;
    }
    return this._sequenceNumber;
  }

  /**
   * Retrieves a {@link solace.TraceContextSetter} object that is used to modify 
   * an appropriate TraceContext associated with a message transparently.
   * When no context is stored in a message it will create and store a creation 
   * context with a message that can be used as an initial
   * transport context at the same time. 
   * It will never override an existing message creation context. 
   * When creation context is present or only transport context is present, it will
   * override an existing transport context information with a newly provided one.
   * 
   * @returns {solace.TraceContextSetter} Transport context setter object associated with this message; never expected to be null
   */
  getTraceContextSetter() {
    // return the parent method
    return super.getTraceContextSetter();
  }

  /**
   * Retrieves a {@link solace.TraceContext} object used for carrying over of the distributed tracing
   * message creation context information usable by intermediary instrumentation across
   * service boundaries. It allows correlating the producer with the consumers of a message,
   * regardless of intermediary instrumentation. It must not be altered by intermediaries.
   *
   * @returns {solace.TraceContext} Message creation context object associated with this message;
   */
  getCreationContext() {
    // call the parent method. Returns a readonly copy
    return super.getCreationContext();
  }
 
  /**
   * Retrieves a {@link solace.TraceContext} object used for carrying over 
   * of the distributed tracing transport context information usable or modifiable 
   * by intermediary instrumentation across service boundaries. 
   * It allows correlating the producer and the consumer with an intermediary.
   * It also allows correlating multiple intermediaries among each other.
   *
   * @returns {solace.TraceContext} Transport context object associated with this message;
   */
  getTransportContext() {
    // call the parent method. Returns a readonly copy
    return super.getTransportContext();
  }

  /**
   * Set the transport context.
   * Called when the message is received from broker
   * 
   * @param {solace.TraceContextSetter} contextSetter 
   */
  setTransportContext(contextSetter) {
    // call the parent method.
    super._setTransportContext(contextSetter);
  }

  /**
   * Retrieves a {@link solace.Baggage} carrier object used for carrying over of the distributed tracing
   * message baggage information across service boundaries.  It must not be altered by
   * intermediaries.
   *
   * @return {solace.Baggage} baggage carrier object associated with this message,
   */
  getBaggage() {
    // returns the parent method baggage instance
    // used for setting and retriving the baggage value 
    return super.getBaggage();
  }

  /**
   * Produces a human-readable dump of the message's properties and
   * contents. Applications must not parse the output, as its format is
   * not a defined part of the API and subject to change.
   *
   * <p>
   * Output can be controlled by the <code>flags</code> parameter. The values are:
   * <ul>
   * <li>{@link MessageDumpFlag.MSGDUMP_BRIEF} Display only the length of the
   *                          binary attachment, xml attachment, and user property map
   * <li>{@link MessageDumpFlag.MSGDUMP_FULL} Display the entire message.
   * </ul>
   * </p>
   *
   * @param {Number} [flags]  Optional flags controlling the output, such as whether
   *                          to include verbose (binary dump) information
   * @returns {String} A string representation of the message.
   */
  dump(flags = MessageDumpFlag.MSGDUMP_FULL) {
    const validFlags = isEnumMember('flags', flags, MessageDumpFlag);
    return MessageDumpUtil.dump(this, validFlags);
  }

  /**
   * Clones the message
   * @returns {Message} A clone of this message
   * @private
   */
  clone() {
    return clone(this, MESSAGE_CLONE_OPTIONS);
  }

  /**
   * Releases all memory associated with this message. All values are reinitialized
   * to defaults. The message is no longer associated with any session or consumer.
   */
  reset() {
    clearMessage(this);
    initMessage(this);
  }

  /**
   * Clears all extended var-len message properties on this message.
   * The message no longer has any extended variable length
   * properties set.
   */
  clearExtendedVarLenParams() {
    clearExtendedVarLenParameters(this);
  }
}

/**
 * A standard property key that clients should use if they want to
 * group messages into different queue partitions.
 * Expected value is UTF-8 encoded up to 255 bytes long string.
 */
Message.SOLCLIENT_USER_PROP_QUEUE_PARTITION_KEY = 'JMSXGroupID';

module.exports.Message = Message;
