const {
  ReplicationGroupMessageId,
  RgmidFactory,
} = require('./lib/replication-group-message-id');
const { Message } = require('./lib/message');
const { MessageCacheStatus } = require('./lib/message-cache-status');
const { MessageDeliveryModeType } = require('./lib/message-delivery-mode-types');
const { MessageDumpFlag } = require('./lib/message-dump-flags');
const { MessageDumpStandardProvider } = require('./lib/message-dump-standard-provider');
const { MessageDumpUtil } = require('./lib/message-dump-util');
const { MessageType } = require('./lib/message-types');
const { MessageOutcome } = require('./lib/message-outcomes');
const { MessageUserCosType } = require('./lib/message-user-cos-types');
const { SolclientFactory } = require('solclient-factory');

/**
 * Creates a {@link solace.Message} instance.
 * @returns {solace.Message} a new message instance.
 * @method
 * @name solace.SolclientFactory.createMessage
 */
SolclientFactory.createMessage = SolclientFactory.createFactory(() => new Message());

/**
 * Creates a {@link solace.ReplicationGroupMessageId} instance from string.
 * A ReplicationGroupMessageId is also a {@link solace.ReplayStartLocation} instance that
 * when set in MessageConsumerProperties indicates that only messages spooled
 * in the replay log since the message after the given ReplicationGroupMesageId
 * should be replayed.
 * @param {string} id a serialized ReplicationGroupMessageId had previously been returned
 *   from {@link solace.ReplicationGroupMessageId.toString}.
 * @returns {solace.ReplicationGroupMessageId} a new ReplicationGroupMessageId instance
 * @method
 * @name solace.SolclientFactory.createReplicationGroupMessageId
 * @throws {solace.OperationError}
 * * if parameter is not a string.
 *   Subcode: {@link solace.ErrorSubcode.PARAMETER_INVALID_TYPE}.
 * * if incorrect format is detected.
 *   Subcode: {@link solace.ErrorSubcode.PARAMETER_OUT_OF_RANGE}.
 */
SolclientFactory.createReplicationGroupMessageId = SolclientFactory.createFactory(id =>
  RgmidFactory.fromString(id));

module.exports.Message = Message;
module.exports.MessageCacheStatus = MessageCacheStatus;
module.exports.MessageDeliveryModeType = MessageDeliveryModeType;
module.exports.MessageDumpFlag = MessageDumpFlag;
module.exports.MessageDumpStandardProvider = MessageDumpStandardProvider;
module.exports.MessageDumpUtil = MessageDumpUtil;
module.exports.MessageType = MessageType;
module.exports.MessageOutcome = MessageOutcome;
module.exports.MessageUserCosType = MessageUserCosType;
module.exports.ReplicationGroupMessageId = ReplicationGroupMessageId;
module.exports.RgmidFactory = RgmidFactory;
